import {Platform} from "react-native";
import {MMKV} from "react-native-mmkv";

export const storage =
    Platform.OS !== "web" || typeof window !== "undefined"
        ? new MMKV()
        : {
              clearAll: () => null,
              getBoolean: () => undefined,
              getString: () => undefined,
              set: () => null,
              delete: () => null,
              contains: () => false,
              getAllKeys: () => [],
              getNumber: () => undefined,
              getBuffer: () => undefined,
              recrypt: () => null,
              addOnValueChangedListener: (_: (key: string) => void) => ({
                  remove: () => null,
              }),
          };

export const asyncStorageAdaptor = {
    getItem: (key: string) => {
        const value = storage.getString(key);

        return Promise.resolve(value || null);
    },
    setItem: (key, value) => {
        storage.set(key, value);

        return Promise.resolve(true);
    },
    removeItem: (key) => {
        storage.delete(key);

        return Promise.resolve();
    },
};

export const syncStorageAdaptor = {
    getItem: (key: string) => {
        return storage.getString(key) || null;
    },
    setItem: (key: string, value: string) => {
        storage.set(key, value);
    },
};
