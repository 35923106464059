import {removeNFLToken as removeRefreshableNFLToken} from "@nfl/rn-shared/src/utils/getRefreshableNFLToken";

// types
import type {UserStateType} from "@nfl/rn-app-nfl-fantasy/src/reducers/userReducer";

import LocalStorage from "@nfl/rn-shared/src/utils/LocalStorage";
// utils
import {getGUID} from "@nfl/rn-shared/src/utils/generateGUID";
import {fetchNFLTokenSSR} from "./fetchNFLTokenSSR";

const NFL_REFRESH_TOKEN_KEY = "nfl.fantasy.v3.refreshableToken";

export const removeNFLAuthToken = () => {
    return removeRefreshableNFLToken({nflTokenKey: NFL_REFRESH_TOKEN_KEY});
};

export function getNFLAuthTokenConfig({userState}: {userState: UserStateType}) {
    const {
        gigyaUID: uid,
        gigyaUIDSignature: uidSignature,
        signatureTimestamp,
    } = userState.nfl || {};

    // return SSR method
    if (typeof window === "undefined") {
        return () => {
            return fetchNFLTokenSSR({
                clientKey: String(process.env.NFL_API_CLIENT_KEY),
                clientSecret: String(process.env.NFL_API_CLIENT_SECRET),
            });
        };
    }

    return async (): Promise<NFLTokenType> => {
        const deviceId = getGUID();

        const localToken = LocalStorage.get(NFL_REFRESH_TOKEN_KEY) as
            | NFLTokenType
            | undefined;

        const offset = 120000;
        if (localToken && localToken?.expiresIn * 1000 > Date.now() + offset) {
            return localToken;
        }

        // local storage token is not there or expired, fetch a new one
        try {
            const response = await fetch("/api/token", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    deviceId,
                    refreshToken: localToken?.refreshToken,
                    signatureTimestamp,
                    uid,
                    uidSignature,
                }),
            });

            if (!response.ok) {
                throw new Error("Failed to fetch NFL token");
            }

            const token = await response.json();
            LocalStorage.set(NFL_REFRESH_TOKEN_KEY, token);
            return token;
        } catch (error) {
            LocalStorage.remove(NFL_REFRESH_TOKEN_KEY);
            throw new Error(`Error fetching NFL token: ${error.message}`);
        }
    };
}
