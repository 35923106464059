export const trackingValues = {
    action: {
        acceptTrade: {
            linkModule: "Trade",
            linkName: "Accept Trade",
            linkType: "Button",
        },
        activateLeagueFailure: {
            linkModule: "Activate League Join Fantasy CTA",
            linkName: "Activate Free League - Fail",
            linkType: "Button",
        },
        activateLeagueSuccess: {
            linkModule: "Activate League Join Fantasy CTA",
            linkName: "Activate Free League - Success",
            linkType: "Button",
        },
        addPlayerAddPlayer: {
            linkModule: "Add Player",
            linkName: "Add Player",
            linkType: "Button",
        },
        addPlayerPlaceWaiver: {
            linkModule: "Add Player",
            linkName: "Place Waiver",
            linkType: "Button",
        },
        addPlayerSubmitWaiverClaim: {
            linkModule: "Add Player",
            linkName: "Submit Waiver Claim",
            linkType: "Button",
        },
        addPlayerSubmitBidWaiverClaim: {
            linkModule: "Add Player",
            linkName: "Submit Bid Waiver Claim",
            linkType: "Button",
        },
        addPlayersInitiateAddPlayer: {
            linkModule: "Add Player",
            linkName: "Initiate Add Player",
            linkType: "Button",
        },
        addPlayersInitiateTradePlayer: {
            linkModule: "Add Player",
            linkName: "Initiate Trade Player",
            linkType: "Button",
        },
        addPlayersInitiateWaiverClaimBid: {
            linkModule: "Claim Budget",
            linkName: "Initiate Claim Budget",
            linkType: "Button",
        },
        addPlayersAddWatchlistPlayer: {
            linkModule: "Add Player",
            linkName: "Add Player Watchlist",
            linkType: "Button",
        },
        addPlayersRemoveWatchlistPlayer: {
            linkModule: "Add Player",
            linkName: "Remove Player Watchlist",
            linkType: "Button",
        },
        approveTrade: {
            linkModule: "Trade",
            linkName: "Approve Trade",
            linkType: "Button",
        },
        bottomNavPlayersButton: {
            linkModule: "Players List Access Point",
            linkName: "Players",
            linkType: "Menu Bar",
        },
        cancelTrade: {
            linkModule: "Trade",
            linkName: "Cancel Trade",
            linkType: "Button",
        },
        cartAdd: {
            linkModule: "In App Purchase",
            linkType: "Cart Add",
        },
        changePasswordSubmit: {
            linkModule: "Change Password",
            linkName: "Save Change Password",
            linkType: "Button",
        },
        comparePlayers: {
            linkModule: "Compare Players Access Point",
            linkName: "Compare Players",
            linkType: "Button",
        },
        createLeague: {
            extraProperties: {
                event_fantasy_lm_signup: "TRUE",
            },
            linkModule: "Fantasy Commissioner Sign Up",
            linkName: "Create Free League",
            linkType: "Fantasy Commissioner Sign Up",
        },
        deleteWaiver: {
            linkModule: "Add Player",
            linkName: "Cancel Waiver",
            linkType: "Button",
        },
        deleteWaiverBid: {
            linkModule: "Add Player",
            linkName: "Cancel Bid Waiver",
            linkType: "Button",
        },
        dismissFantasyPlusOptimizeMessage: {
            linkModule: "Team Section Actions",
            linkName: "Close Popup",
            linkType: "NFL Plus Purchase Access Point",
        },
        draftAutopick: {
            linkModule: "DraftAutopick",
            linkName: "On", // "On" or "Off"
            linkType: "Switch",
        },
        draftBannerAction: {
            linkModule: "Draft Reminder",
            linkType: "System Message",
            linkName: "Enter Draft",
        },
        draftPlayer: {
            linkModule: "Draft Player",
            linkType: "Standard", // "Standard" or "Auction"
            linkName: "", // Player name
        },
        editProfileSave: {
            linkModule: "Edit Profile",
            linkName: "Save Edit Profile",
            linkType: "Button",
        },
        editTeamInfo: {
            linkModule: "Edit Team Info",
            linkName: "save",
            linkType: "button",
        },
        editTeamInfoDiscard: {
            linkModule: "Edit Team Info",
            linkName: "Discard Changes",
            linkType: "Discard Changes",
        },
        editTeamRoster: {
            linkModule: "Roster Lineup Change",
            linkName: "Lineup Change",
            linkType: "Edit Lineup",
        },
        fanLeaguesApplyNote: {
            linkModule: "Discussion Post",
            linkName: "Post Message",
            linkType: "Message",
        },
        fanLeaguesApplyButton: {
            linkModule: "Fan Leagues Apply for League",
            linkName: "Submit",
            linkType: "Button",
        },
        fanLeaguesJoinButton: {
            linkModule: "Fan Leagues Join League",
            linkName: "Submit",
            linkType: "Button",
        },
        fanLeaguesSignUp: {
            extraProperties: {
                event_fantasy_signup: "TRUE",
            },
            linkModule: "Fantasy Sign Up",
            linkName: "Join Fan League",
            linkType: "Fantasy Sign Up",
        },
        fantasyPlusComparePlayers: {
            linkModule: "Fantasy Plus Section Actions",
            linkName: "Compare",
            linkType: "Waiver Pro",
        },
        fantasyPlusHeaderButton: {
            linkModule: "Fanatasy Plus Header Icon",
            linkName: "Fantasy Plus",
            linkType: "",
            linkPosition: "1:1",
        },
        fantasyPlusUpsell: {
            linkModule: "Fantasy+ Upsell",
            linkName: "", // dynamic
            linkType: "Ways to Access",
        },
        fantasyPlusUpsellDashboard: {
            linkModule: "Fantasy Plus Hero CTA",
            linkName: "", // dynamic
            linkType: "NFL Plus Purchase Access Point",
        },
        fantasyPlusUpsellSettingsLearnMore: {
            linkModule: "Fantasy Plus Promo",
            linkName: "Learn More",
            linkType: "NFL Plus Purchase Access Point",
            linkPosition: "1:1",
        },
        feedDiscussionPost: {
            linkModule: "Discussion Post",
            linkName: "Post Message", // "Post New Discussion" or "Post Message"
            linkType: "Message",
        },
        feedHomeButton: {
            linkModule: "Feed Home Button",
            linkName: "Home Icon",
            linkType: "Home Page Access Point",
        },
        gamePassHideButtonCondensedGames: {
            linkModule: "GamePass Upsell",
            linkName: "Condensed Games - Learn More",
            linkType: "Close",
        },
        gamePassHideButtonLiveAudio: {
            linkModule: "GamePass Upsell",
            linkName: "Live Audio - Learn More",
            linkType: "Close",
        },
        gamePassHideButtonSearch: {
            linkModule: "GamePass Upsell",
            linkName: "Search - Learn More",
            linkType: "Close",
        },
        gamePassLearnMoreCondensedGamesAllFeatures: {
            linkModule: "GamePass Upsell",
            linkName: "Condensed Games - All Features",
            linkType: "Ways to Access",
        },
        gamePassLearnMoreButtonLiveAudioAllFeatures: {
            linkModule: "GamePass Upsell",
            linkName: "Live Audio - All Features",
            linkType: "Ways to Access",
        },
        gamePassLearnMoreButtonSearchAllFeatures: {
            linkModule: "GamePass Upsell",
            linkName: "Search - All Features",
            linkType: "Ways to Access",
        },
        gamePassLearnMoreButtonCondensedGames: {
            linkModule: "GamePass Upsell",
            linkName: "Condensed Games - Learn More",
            linkType: "Ways to Access",
        },
        gamePassLearnMoreButtonLiveAudio: {
            linkModule: "GamePass Upsell",
            linkName: "Live Audio - Learn More",
            linkType: "Ways to Access",
        },
        gamePassLearnMoreButtonSearch: {
            linkModule: "GamePass Upsell",
            linkName: "Search - Learn More",
            linkType: "Ways to Access",
        },
        gamePassWatchNowButton: {
            linkModule: "GPI Upsell",
            linkName: "Watch Now",
            linkType: "Ways to Access",
        },
        gamesHomeButton: {
            linkModule: "Games Home Button",
            linkName: "Home Icon",
            linkType: "Home Page Access Point",
        },
        generalNotificationsFantasyUpdates: {
            linkModule: "General Notifications",
            linkName: "NFL Fantasy Updates",
            linkType: "On/Off",
        },
        homeSignInButton: {
            linkModule: "Home Log In Button",
            linkName: "Sign In",
            linkType: "Button",
        },
        homeMockDraftButton: {
            linkModule: "Mock Draft CTA",
            linkName: "Mock Drafts",
            linkType: "Draft Strategy",
        },
        inAppPurchase: {
            linkModule: "In App Purchase",
            linkName: "", // Product category
            linkType: "Purchase",
        },
        inAppPurchaseCartAdd: {
            linkModule: "In App Purchase",
            linkName: "", // Product category
            linkType: "Cart Add",
        },
        inAppPush: {
            linkModule: "NFL Fantasy App Modal",
            linkName: "", // "Engage Modal CTA" or "Close Modal"
            linkType: "One Signal Access Point",
        },
        joinLeagueSignUpAutopick: {
            extraProperties: {
                event_fantasy_signup: "TRUE",
            },
            linkModule: "Fantasy Sign Up",
            linkName: "Join Autopick League",
            linkType: "Fantasy Sign Up",
        },
        joinLeagueSignUpInvite: {
            extraProperties: {
                event_fantasy_signup: "TRUE",
            },
            linkModule: "Fantasy Sign Up",
            linkName: "Join Invite League",
            linkType: "Fantasy Sign Up",
        },
        joinLeagueSignUpFree: {
            extraProperties: {
                event_fantasy_signup: "TRUE",
            },
            linkModule: "Fantasy Sign Up",
            linkName: "Join Free League",
            linkType: "Fantasy Sign Up",
        },
        launchNflPlusUpsell: {
            linkModule: "NFL Plus Hero VPO",
            linkName: "Subcribe Now",
            linkType: "NFL Plus Purchase Access Point",
        },
        leagueFeedToggle: {
            linkModule: "League Feed",
            linkName: "Toggle",
            linkType: "Open/Close", // overridden
        },
        leagueFeedLink: {
            linkModule: "League Feed",
            linkName: "", // overridden
            linkType: "Link",
        },
        leaguePublishToggle: {
            linkModule: "League Setup Make Public League",
            linkName: "",
            linkType: "Yes/No",
        },
        leagueReminderToActivateSuccess: {
            linkModule: "Activate League Join Fantasy CTA",
            linkName: "Remind Commissioner to Activate League - Success",
            linkType: "Button",
        },
        leagueReminderToActivateFailure: {
            linkModule: "Activate League Join Fantasy CTA",
            linkName: "Remind Commissioner to Activate League - Fail",
            linkType: "Button",
        },
        leagueSetupDraftReminderButton: {
            linkModule: "Draft Date Reminder",
            linkName: "Add to Calendar",
            linkType: "Button",
        },
        leagueSetupInviteFriendsButton: {
            linkModule: "My Leagues",
            linkName: "Invite Friends",
            linkType: "Button",
        },
        leagueSetupAllowManagersInviteToggle: {
            linkModule: "League Setup Allow Managers to Invite",
            linkName: "Allow Managers to Invite",
            linkType: "On/Off",
        },
        loginSuccess: {
            extraProperties: {
                nfl_sign_in: "TRUE",
            },
            linkModule: "NFL Account Sign In",
            linkName: "NFL",
            linkType: "Sign In",
        },
        matchupRecapViewButton: {
            linkModule: "Matchup Recap Access Point",
            linkName: "View Matchup Recap",
            linkType: "Button",
        },
        matchupRecapReadMore: {
            linkModule: "Expand Matchup Recap",
            linkName: "Keep Reading",
            linkType: "Button",
        },
        myAccountChangePassword: {
            linkModule: "Change Password",
            linkName: "Initiate Change Password",
            linkType: "Button",
        },
        myAccountLogOut: {
            linkModule: "Log Out",
            linkName: "Log Out My Account",
            linkType: "Button",
        },
        myAccountViewProfile: {
            linkModule: "View Profile",
            linkName: "Initiate View Profile",
            linkType: "Button",
        },
        myLeaguesEnterDraftButton: {
            linkModule: "My Leagues",
            linkName: "Enter Draft",
            linkType: "Button",
        },
        myLeaguesInviteFriendsButton: {
            linkModule: "My Leagues",
            linkName: "Invite Friends",
            linkType: "Button",
        },
        nflNetworkWatchTile: {
            linkModule: "Channel Changer Card",
            linkName: "NFL Network",
            linkType: "", // depends on user's subscription
        },
        nflProUpSellPromo: {
            linkModule: "NFL Pro Promo",
            linkName: "UPGRADE NOW",
            linkType: "NFL Plus Premium Purchase Access Point",
            contentPosition: "1",
            contentLocation: "1",
            linkPosition: "1:1",
            upsellType: "nfl-pro-promo",
        },
        nflProLaunchPromo: {
            linkModule: "NFL Pro Promo",
            linkName:
                "view additional plays with the full film room experience on the nfl pro website",
            linkType: "NFL Pro Access Point",
            contentPosition: "1",
            contentLocation: "1",
            linkPosition: "1:1",
            upsellType: "nfl-pro-promo",
        },
        nonParticipatingJoinLeagueButton: {
            linkModule: "No League Join Fantasy CTA",
            linkName: "Join a  League",
            linkType: "Button",
        },
        nonParticipatingDraftNowButton: {
            linkModule: "No League Join Fantasy CTA",
            linkName: "Draft Now",
            linkType: "Button",
        },
        nonParticipatingCreateLeagueButton: {
            linkModule: "No League Join Fantasy CTA",
            linkName: "Create a League",
            linkType: "Button",
        },
        optimizeLineup: {
            linkModule: "Roster Optimize Lineup",
            linkName: "Optimize Lineup",
            linkType: "Button",
        },
        optimizeProSetting: {
            linkModule: "Fantasy Plus Section Actions",
            linkType: "Optimize Pro",
            linkName: "", // Off, Auto-Optimize Lineup, Auto-Swap Inactives
        },
        optimizeProUpsell: {
            linkModule: "Lineup Method Selection",
            linkName: "Set Lineup Method",
            linkType: "NFL Plus Purchase Access Point",
            linkPosition: "1:1",
        },
        participatingJoinLeagueButton: {
            linkModule: "In League Join Fantasy CTA",
            linkName: "Join a  League",
            linkType: "Button",
        },
        participatingCreateLeagueButton: {
            linkModule: "In League Join Fantasy CTA",
            linkName: "Create a League",
            linkType: "Button",
        },
        playerProfileNewsShowMore: {
            linkModule: "Player Card Overview",
            linkName: "Show More",
            linkType: "News",
        },
        playerProfileAdd: {
            linkModule: "Add Player",
            linkName: "Initiate Add",
            linkType: "Button",
        },
        playerProfileClaimBudget: {
            linkModule: "Claim Budget",
            linkName: "Initiate Claim Budget",
            linkType: "Button",
        },
        playerProfileDrop: {
            linkModule: "Drop",
            linkName: "Initiate Drop",
            linkType: "Button",
        },
        playerProfileTrade: {
            linkModule: "Trade",
            linkName: "Initiate Trade",
            linkType: "Button",
        },
        proposeTrade: {
            linkModule: "Trade",
            linkName: "Propose Trade",
            linkType: "Button",
        },
        pushNotificationsOptIn: {
            linkModule: "Push Notifications",
            linkName: "Turn On Notifications",
            linkType: "Button",
        },
        pushNotificationsOptOut: {
            linkModule: "Push Notifications",
            linkName: "Not Now",
            linkType: "Button",
        },
        redZonePromoButton: {
            linkModule: "RedZone Upsell",
            linkName: "Subscribe Now",
            linkType: "Ways to Access",
        },
        redZoneUpsellButton: {
            linkModule: "RedZone Upsell",
            linkName: "", // Button Title
            linkType: "Ways to Access",
        },
        redZoneWatchTile: {
            linkModule: "RedZone Upsell",
            linkName: "Watch RedZone Channel",
            linkType: "Ways to Access",
        },
        rejectTrade: {
            linkModule: "Trade",
            linkName: "Reject Trade",
            linkType: "Button",
        },
        remindDiscussionPost: {
            linkModule: "Discussion Post",
            linkName: "Post Message",
            linkType: "Message",
        },
        scoreboardFilterButton: {
            linkModule: "Top Players Filter",
            linkName: "", // "RB"
            linkType: "Filter",
        },
        settingsTVProviderConnect: {
            linkModule: "Watch Live Access Point",
            linkName: "Connect",
            linkType: "Button",
        },
        settingsLogOut: {
            linkModule: "Log Out",
            linkName: "Log Out Settings",
            linkType: "Button",
        },
        siteMessageDismissAction: {
            linkModule: "Site Message",
            linkName: "Dismiss",
            linkType: "", // Populate with message text
        },
        socialLoginRemovalCreatePassword: {
            linkModule: "Social Auth Password Update",
            linkName: "", // Populate with button name
            linkType: "Manage nfl.com Profile",
        },
        sortAdaptiveRank: {
            linkModule: "Draft Section Actions",
            linkName: "Filter by Adaptive Ranking",
            linkType: "filter",
        },
        standingsHomeButton: {
            linkModule: "Standings Home Button",
            linkName: "Home Icon",
            linkType: "Home Page Access Point",
        },
        teamAddPlayersButton: {
            linkModule: "Players List Access Point",
            linkName: "Add Players",
            linkType: "Button",
        },
        teamHomeButton: {
            linkModule: "Team Home Button",
            linkName: "Home Icon",
            linkType: "Home Page Access Point",
        },
        tradeBuilderButton: {
            linkModule: "Trade Builder",
            linkName: "", // overridden
            linkType: "Button",
        },
        updateWaiverBid: {
            linkModule: "Add Player",
            linkName: "Update Bid Waiver",
            linkType: "Button",
        },
        vetoTrade: {
            linkModule: "Trade",
            linkName: "Veto Trade",
            linkType: "Button",
        },
        viewAdaptiveRank: {
            linkModule: "Draft Section Actions",
            linkName: "View Adaptive Ranking",
        },
        viewProfileLogOut: {
            linkModule: "Log Out",
            linkName: "Log Out View Profile",
            linkType: "Button",
        },
        waiverProUpsell: {
            linkModule: "Fantasy Plus Feature Card",
            linkName: "See Your Full List",
            linkType: "NFL Plus Purchase Access Point",
            linkPosition: "1:1",
        },
        watchLiveButton: {
            linkModule: "Watch Live Header Icon",
            linkName: "Watch Live Icon",
            linkPosition: "1:1",
            extraProperties: {
                upsellType: "watch-live-header-icon",
                upsellLocation: "nfl fantasy app:home:home:landing",
            },
        },
        watchLiveHomeCTA: {
            linkModule: "Watch Live Access Point",
            linkName: "Watch Live Games - Home",
            linkType: "Button",
        },
        watchLivePlayerProfileCTA: {
            linkModule: "Watch Live Access Point",
            linkName: "Watch Live Games - Player Profile",
            linkType: "Button",
        },
        watchRedzonePlayerProfileCTA: {
            linkModule: "RedZone Upsell",
            linkName: "Watch RedZone Channel",
            linkType: "Ways to Access",
        },
        watchRedzonePlayersPromoCTA: {
            extraProperties: {
                carouselName: "RedZone with NFL Network",
                thumbnailType: "no thumbnail",
                thumbnaleName: "no thumbnail",
                contentPosition: 1,
                contentLocation: 1,
            },
            linkModule: "NFL Plus RedZone Watch Live Card",
            linkName: "Watch Live",
            linkPosition: "1:1",
            linkType: "NFL Plus Premium Purchase Access Point",
            upsellLocation: "nfl fantasy app:team:roster:reg 1",
            upsellType: "redzone-watch-now-card",
        },
        widgetGameCenterMatchup: {
            linkModule: "NFL App Widget",
            linkType: "Gamecenter Access Point",
            linkName: "Fantasy Matchup Widget",
        },
        widgetLiveGames: {
            linkModule: "NFL App Widget",
            linkType: "Live National Access Point",
            linkName: "Fantasy Live Game Widget",
        },
    },
    view: {
        account: {
            pageDetail: "landing",
            siteSection: "account",
            siteSubsection: "account",
        },
        addPlayer: {
            pageDetail: "add player",
            siteSection: "players",
            siteSubsection: "players",
        },
        addPlayerSubmitBidWaiverClaim: {
            pageDetail: "enter bid amount",
            siteSection: "players",
            siteSubsection: "players",
        },
        celebration: {
            pageDetail: "",
            siteSection: "team",
            siteSubsection: "celebration",
        },
        comparePlayers: {
            pageDetail: "compare",
            siteSection: "",
            siteSubsection: "compare players",
        },
        draftSalaryCap: {
            pageDetail: "auction", // ":${activeTab}"
            siteSection: "draft",
            siteSubsection: "auction draft",
        },
        draftBoard: {
            pageDetail: "draft board",
            siteSection: "draft",
            siteSubsection: "standard draft",
        },
        draftChat: {
            pageDetail: "chat",
            siteSection: "draft",
            siteSubsection: "standard draft",
        },
        draftPlayers: {
            pageDetail: "players", // ":${position}"
            siteSection: "draft",
            siteSubsection: "standard draft",
        },
        draftQueue: {
            pageDetail: "queue",
            siteSection: "draft",
            siteSubsection: "standard draft",
        },
        draftRoster: {
            pageDetail: "roster", // ":${team}"
            siteSection: "draft",
            siteSubsection: "standard draft",
        },
        draftSettings: {
            pageDetail: "settings",
            siteSection: "draft",
            siteSubsection: "standard draft",
        },
        editTeamRoster: {
            pageDetail: "edit", // "week ${week}"
            siteSection: "team",
            siteSubsection: "roster",
        },
        fanLeagues: {
            pageDetail: "fan leagues",
            siteSection: "home",
            siteSubsection: "join a league",
        },
        fanLeaguesApply: {
            pageDetail: "fan leagues:apply",
            siteSection: "home",
            siteSubsection: "join a league",
        },
        fanLeaguesFilters: {
            pageDetail: "fan leagues:filter",
            siteSection: "home",
            siteSubsection: "join a league",
        },
        fanLeaguesJoin: {
            pageDetail: "fan leagues:league detail",
            siteSection: "home",
            siteSubsection: "join a league",
        },
        fantasyPlusDashboard: {
            pageDetail: "landing",
            siteSection: "fantasy-plus",
            siteSubsection: "dashboard",
        },
        fantasyPlusProduct: {
            pageDetail: "learn-more",
            siteSection: "fantasy-plus",
            siteSubsection: "dashboard",
        },
        fantasyPlusUpsell: {
            pageDetail: "upsell",
            siteSection: "",
            siteSubsection: "fantasy+",
        },
        feed: {
            pageDetail: "landing",
            siteSection: "feed",
            siteSubsection: "my feed",
        },
        feedFantasyNews: {
            pageDetail: "landing",
            siteSection: "feed",
            siteSubsection: "fantasy news",
        },
        gameCenterMatchup: {
            pageDetail: "", // "week ${week}"
            siteSection: "game center",
            siteSubsection: "matchup",
        },
        gameCenterScoreboard: {
            pageDetail: "", // "week ${week}"
            siteSection: "game center",
            siteSubsection: "scoreboard",
        },
        gamePassLearnMore: {
            pageDetail: "landing",
            siteSection: "gamepass",
            siteSubsection: "upsell",
        },
        gamePassUpsell: {
            pageDetail: "landing",
            siteSection: "gamepass international",
            siteSubsection: "upsell",
        },
        hallOfFame: {
            pageDetail: "all time",
            siteSection: "league",
            siteSubsection: "hall of fame",
        },
        hallOfFameSeason: {
            pageDetail: "", // ${season}
            siteSection: "league",
            siteSubsection: "hall of fame",
        },
        home: {
            pageDetail: "landing",
            siteSection: "home",
            siteSubsection: "home",
        },
        joinLeagueSummary: {
            pageDetail: "name your team",
            siteSection: "home",
            siteSubsection: "join a league",
        },
        leagueFeed: {
            pageDetail: "landing",
            siteSection: "league feed",
            siteSubsection: "feed",
        },
        leaguePublish: {
            pageDetail: "publish league",
            siteSection: "league",
            siteSubsection: "setup",
        },
        leagueSetup: {
            pageDetail: "landing",
            siteSection: "league",
            siteSubsection: "setup",
        },
        leagueStandings: {
            pageDetail: "landing",
            siteSection: "league",
            siteSubsection: "", // standings/division/playoffs/overall
        },
        leagueRules: {
            pageDetail: "",
            siteSection: "league",
            siteSubsection: "league rules",
        },
        optimizeLineup: {
            pageDetail: "modal",
            siteSection: "team",
            siteSubsection: "optimize lineup",
        },
        teamRosterLanding: {
            pageDetail: "", // "week ${week}"
            siteSection: "team",
            siteSubsection: "roster",
        },
        matchupRecap: {
            pageDetail: "", // "week ${week}:recap"
            siteSection: "game center",
            siteSubsection: "matchup",
        },
        mvpdLanding: {
            pageDetail: "landing",
            siteSection: "",
            siteSubsection: "select provider",
        },
        mvpdProviderList: {
            pageDetail: "provider list",
            siteSection: "",
            siteSubsection: "select provider",
        },
        namiNflPlusPaywall: {
            pageDetail: "nfl plus product selection",
            siteSection: "account",
            siteSubsection: "upsell",
        },
        optimizePro: {
            pageDetail: "landing",
            siteSection: "fantasy-plus",
            siteSubsection: "optimize-pro",
        },
        optimizeProNotifications: {
            pageDetail: "notification settings",
            siteSection: "fantasy-plus",
            siteSubsection: "optimize-pro",
        },
        players: {
            pageDetail: "player search",
            siteSection: "players",
            siteSubsection: "players",
        },
        redZoneUpsell: {
            pageDetail: "landing",
            siteSection: "redzone",
            siteSubsection: "upsell",
        },
        redZoneAffiliateUpsell: {
            pageDetail: "non-subscriber affiliate",
            siteSection: "redzone",
            siteSubsection: "upsell",
        },
        tradeMachine: {
            pageDetail: "landing",
            siteSection: "fantasy-plus",
            siteSubsection: "trade-machine",
        },
        tradeMachineAnalyzer: {
            pageDetail: "trade analyzer",
            siteSection: "fantasy-plus",
            siteSubsection: "trade-machine",
        },
        trade: {
            pageDetail: "trade",
            siteSection: "trade-builder",
            siteSubsection: "trade-builder",
        },
        tradeDrop: {
            pageDetail: "trade-drop",
            siteSection: "trade-builder",
            siteSubsection: "trade-builder",
        },
        tradePropose: {
            pageDetail: "trade-propose",
            siteSection: "trade-builder",
            siteSubsection: "trade-builder",
        },
        tradeReview: {
            pageDetail: "trade-review",
            siteSection: "trade-builder",
            siteSubsection: "trade-builder",
        },
        trades: {
            pageDetail: "trades",
            siteSection: "team",
            siteSubsection: "players",
        },
        splashScreen: {
            pageDetail: "splash",
            siteSection: "splash",
            siteSubsection: "splash",
        },
        tournamentInformationScreen: {
            pageDetail: "info",
            siteSection: "league",
            siteSubsection: "nfl tournament",
        },
        tournamentStandingsScreen: {
            pageDetail: "standings",
            siteSection: "league",
            siteSubsection: "nfl tournament",
        },
        waiverProBestAvailable: {
            pageDetail: "best available",
            siteSection: "fantasy-plus",
            siteSubsection: "waiver-pro",
        },
        waiverProLineup: {
            pageDetail: "lineup",
            siteSection: "fantasy-plus",
            siteSubsection: "waiver-pro",
        },
        waiversWaiverReport: {
            pageDetail: "waiver report",
            siteSection: "team",
            siteSubsection: "waiver",
        },
        waiversPending: {
            pageDetail: "pending",
            siteSection: "team",
            siteSubsection: "waiver",
        },
        watch: {
            pageDetail: "landing",
            siteSection: "watch",
            siteSubsection: "watch",
        },
    },
};
