function okResponse(response: Response): Promise<any> {
    return response
        .text()
        .then((text) => JSON.parse(text))
        .catch(() => ({
            status: response.status,
            success: true,
        }));
}

function errorResponse(
    response:
        | Response
        | {
              message: string;
              status: number;
          }
): Promise<any> {
    const {status} = response || {};
    const err = {
        error: true,
        errorCode: status,
        errorStatus: status,
        message: "An unspecified error occurred",
    };

    try {
        return response // @ts-expect-error FixMe
            .text()
            .then((text) => {
                const result = JSON.parse(text);
                return {
                    errorCode: response.status,
                    ...result,
                    message: result.message || result.errorMessage,
                };
            })
            .catch(() => err);
    } catch (e) {
        return Promise.resolve(err);
    }
}

export function asyncUnifiedResponse(
    response:
        | Response
        | {
              message: string;
              status: number;
          }
): Promise<any> {
    const responseActions = {
        "200": okResponse,
        "202": okResponse,
        "204": okResponse,
        "304": okResponse,
        "400": errorResponse,
        "401": errorResponse,
        "404": errorResponse,
        "406": errorResponse,
        "500": errorResponse,
        "503": errorResponse,
    };

    if (response && responseActions[response.status]) {
        return responseActions[response.status](response);
    }

    return errorResponse(response);
}
