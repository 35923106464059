import {NFLRefreshableToken} from "@nfl/nfl-api/src/NFLToken/NFLRefreshableToken";

export const fetchNFLTokenSSR = async ({
    clientKey,
    clientSecret,
}: {
    clientKey: string;
    clientSecret: string;
}): Promise<NFLTokenType> => {
    return NFLRefreshableToken.getToken({
        clientKey,
        clientSecret,
        deviceId: "",
    }).catch(() => null as any);
};
