// biome-ignore lint/correctness/noUnusedImports: still needed for web
import * as React from "react";
// import { Link } from 'react-router-dom'; TODO: use Link for intra page navigation

// types
import type {NavItem} from "../types";

const navItemClick = (e) => {
    const link = e.target;
    const target = link.getAttribute("target");
    const params = link.getAttribute("data-target-features");
    if (target && target !== "_blank") {
        e.preventDefault();
        const url = link.getAttribute("href");
        const win = window.open(url, target, params);
        if (win) {
            win.focus();
        }
    }
};

type NavItemsProps = {
    items?: NavItem[];
    pageName: string;
};

const NavItems = (props: NavItemsProps) => {
    const {items = [], pageName} = props;

    return (
        <div className="content">
            {items.map((item) => {
                let className = "nav-item";
                const isParentName =
                    item.parentNavItem && pageName === item.parentNavItem;
                if (pageName === item.pageName || isParentName) {
                    className += " selected";
                }
                const pageKey = item.pageName
                    ? item.label + item.pageName
                    : item.label;
                const {target, targetFeatures} = item;
                const onClick = target ? navItemClick : undefined;
                const asLink = item.pageName || item.url;

                return (
                    <span className={className} key={pageKey}>
                        {asLink && (
                            <a
                                data-target-features={targetFeatures}
                                href={item.url}
                                target={target}
                                onClick={onClick}
                            >
                                {item.label}
                            </a>
                        )}
                        {!asLink && <span>{item.label}</span>}
                    </span>
                );
            })}
        </div>
    );
};

export default NavItems;
