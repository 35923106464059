export const formatBody = (body: object) => {
    const formBody: string[] = [];
    Object.keys(body).forEach((property) => {
        const encodedKey = encodeURIComponent(property);
        const encodedValue = encodeURIComponent(body[property]);

        formBody.push(`${encodedKey}=${encodedValue}`);
    });
    const formBodyResult = formBody.join("&");
    return formBodyResult;
};
