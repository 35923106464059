import {canUseDOM} from "exenv";
import hash from "object-hash";

const scriptsCache = {};

type ScriptType = {
    id?: string;
    src: string;
};

export const getScript = (scriptObject: ScriptType): Promise<any> => {
    const {src, id} = scriptObject;

    const scriptLoader = new Promise((resolve: any, reject: any) => {
        if (canUseDOM) {
            if (!scriptsCache[src]) {
                scriptsCache[src] = {
                    scriptCheck: null,
                    isLoading: false,
                    isLoaded: false,
                };
            }

            const currentScriptCache = scriptsCache[src];

            if (currentScriptCache.isLoaded) {
                return resolve(src);
            }

            if (currentScriptCache.isLoading) {
                scriptsCache[src].scriptCheck = setInterval(() => {
                    const cache = scriptsCache[src];
                    if (!cache.isLoaded) {
                        return false;
                    }

                    if (cache.scriptCheck) {
                        clearInterval(cache.scriptCheck);
                        scriptsCache[src].scriptCheck = null;
                    }

                    return resolve(src);
                }, 0);
                return src;
            }

            scriptsCache[src].isLoading = true;

            const script = window.document.createElement("script");
            script.type = "text/javascript";
            script.id = id || hash(src);
            // @ts-expect-error FixMe
            if (script.readyState) {
                // IE
                // @ts-expect-error FixMe
                script.onreadystatechange = () => {
                    if (
                        // @ts-expect-error FixMe
                        script.readyState === "loaded" ||
                        // @ts-expect-error FixMe
                        script.readyState === "complete"
                    ) {
                        // @ts-expect-error FixMe
                        script.onreadystatechange = null;
                    }

                    return resolve(src);
                };
            } else {
                // Everything else
                script.onload = () => resolve(src);
                // @ts-expect-error FixMe
                script.onerror = (e: Error) => {
                    scriptsCache[src] = {
                        scriptCheck: null,
                        isLoading: false,
                        isLoaded: true,
                    };
                    reject(e);
                    throw new Error(`Couldn't load ${src}`);
                };
            }

            script.src = src;
            window.document.getElementsByTagName("head")[0].appendChild(script);

            return script;
        }

        return reject(new Error("Can not load external scripts without a DOM"));
    });

    return scriptLoader.then((loadedSrc: string) => {
        scriptsCache[loadedSrc].isLoaded = true;
        return loadedSrc;
    });
};

export const getScripts = (
    srcAr: Array<ScriptType>,
    onComplete?: () => void,
    onError?: (e?: Error) => void
): Promise<any> =>
    srcAr
        .reduce(
            (accScriptResponse: Promise<any>, src: ScriptType) =>
                accScriptResponse
                    .then(() => getScript(src))
                    .catch((e: Error) => {
                        if (typeof onError === "function") {
                            onError(e);
                        }
                    }),
            Promise.resolve()
        )
        .then(() => {
            if (typeof onComplete === "function") {
                onComplete();
            }
        });
