import {canUseDOM} from "exenv";

class LocalStorage {
    /**
     * Retrieves stored data for the key provided.
     *
     * @method get
     * @param {String} key
     * @returns {*}
     */
    get(key: string): (Record<string, any> | Array<any>) | null | undefined {
        if (!canUseDOM) {
            return null;
        }

        let data;
        try {
            if (key) {
                const item = localStorage.getItem(key);
                if (item) {
                    data = JSON.parse(item);
                }
            }
        } catch (err) {
            global.console.error(err.message);

            return {
                error: true,
                message: err.message,
                status: "failure",
            };
        }

        return data;
    }

    /**
     * Retrieves stored data for the key provided.
     *
     * @method get
     * @param {String} key
     * @returns {*}
     */
    getRaw(key: string): (Record<string, any> | Array<any>) | null | undefined {
        if (!canUseDOM) {
            return null;
        }

        let data;
        try {
            if (key) {
                data = localStorage.getItem(key);
            }
        } catch (err) {
            global.console.error(err.message);

            return {
                error: true,
                message: err.message,
                status: "failure",
            };
        }

        return data;
    }

    /**
     * Store data for the provided key.
     *
     * @method set
     * @param {String} key
     * @param {*} data
     * @returns {Object} an response object
     */
    set(key: string, data: unknown = {}) {
        if (!canUseDOM) {
            return null;
        }

        try {
            const storeData = JSON.stringify(data);

            localStorage.setItem(key, storeData);
        } catch (err) {
            global.console.error(err.message);

            return {
                error: true,
                message: err.message,
                status: "failure",
            };
        }

        return {
            status: "success",
        };
    }

    /**
     * Store data for the provided key without formatting any value.
     *
     * @method set
     * @param {String} key
     * @param {String} key
     * @returns {Object} an response object
     */
    setRaw(key: string, data: string) {
        if (!canUseDOM) {
            return null;
        }

        try {
            localStorage.setItem(key, data);
        } catch (err) {
            global.console.error(err.message);

            return {
                error: true,
                message: err.message,
                status: "failure",
            };
        }

        return {
            status: "success",
        };
    }

    /**
     * Removes stored data for the provided key.
     *
     * @method remove
     * @param {String} key
     * @returns {Object} an response object
     */
    remove(key: string) {
        if (!canUseDOM) {
            return null;
        }
        try {
            localStorage.removeItem(key);
        } catch (err) {
            global.console.error(err.message);

            return {
                error: true,
                message: err.message,
                status: "failure",
            };
        }

        return {
            status: "success",
        };
    }
}

export default new LocalStorage();
