/* istanbul ignore file */
import axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from "axios";
import {NFLAPIConfig} from "./NFLAPIConfig/NFLAPIConfig";

interface APIError extends Error {
    config?: AxiosRequestConfig;
    response?: AxiosResponse;
    _retry?: boolean;
}

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => {
        // Check for incorrect 200 on 404s
        if (response.data?.errorCode === 404) {
            const error: APIError = new Error("Not found");
            error.response = response;
            error.response.status = 404;
            throw error;
        }
        return response;
    },
    async (error: AxiosError) => {
        const originalRequest = error.config as AxiosRequestConfig & {
            _retry?: boolean;
        };
        if (error.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            try {
                const authToken: NFLTokenType =
                    await NFLAPIConfig.getAuthToken();
                axiosInstance.defaults.headers.common.Authorization = `Bearer ${authToken.accessToken}`;
                // Delay before retrying the request to avoid rapid failures
                await new Promise((resolve) => {
                    setTimeout(resolve, 500);
                });
                return axiosInstance(originalRequest);
            } catch (tokenError) {
                return Promise.reject(tokenError);
            }
        }
        return Promise.reject(error);
    }
);

export {axiosInstance};
