import {CORE_API_BASE_URL_MAP, SCREEN_PASS_URL} from "../constants/api";

export const getUrlParams = (url: string) => new Set(url.match(/(?<=:)\w+/g));

export const replaceUrlParams = (url: string, params: Record<string, any>) =>
    url.replace(/:(\w+)/g, (_, key) => params[key] ?? "");

// Provided `string` matches a parameterized `url`, returns the parameters
// Examples:
//     matchUrlParams('/league/:leagueId/team/:teamId/gamecenter', '/league/123/team/456/gamecenter')
//          => {leagueId: '123', teamId: '456'}
//     matchUrlParams('/league/:leagueId/team/:teamId/gamecenter', 'https://fantasy.nfl.com/league/123/team/456/gamecenter')
//          => {leagueId: '123', teamId: '456'}
//     matchUrlParams('/home', '/home')
//          => {}
//     matchUrlParams('/league/:leagueId/team/:teamId/gamecenter', '/league/123/team/456')
//          => false
export const matchUrlParams = (url: string, string: string) => {
    const params = [] as string[];
    const re = url
        .replace(/^http[s]?:\/\/.+?\/[\/]?/, "/")
        .replace(/[.*+?^${}()|[\]\\]/g, "\\$&") // escape regex special characters
        .replace(/:\w+/g, (name) => {
            params.push(name.substring(1));
            return "([^/&]*)";
        });
    const match = string.match(`^${re}$`);
    if (!match) {
        return false;
    }
    return params.reduce(
        (acc, name, index) => ({...acc, [name]: match[index + 1]}),
        {}
    );
};

export const parseQuery = (params: string | undefined) => {
    const result = {} as Record<string, string>;
    params?.split("&").forEach((pair) => {
        const [key, value] = pair.split("=");
        if (!key) {
            return;
        }
        result[decodeURIComponent(key)] = decodeURIComponent(value);
    });
    return result;
};

export function getHostName(hostname?: string) {
    if (!hostname) {
        if (typeof window !== "undefined") {
            return window.location.hostname;
        }
        return process.env.CORE_WEB_BASE_URL;
    }

    return hostname;
}

export const getFantasyApiUrl = () => {
    const hostname = getHostName();
    if (hostname && CORE_API_BASE_URL_MAP[hostname]) {
        return CORE_API_BASE_URL_MAP[hostname];
    }
    return "/api/proxy";
};

export const getWebBaseUrl = (defaultHostname?: string) => {
    const hostname = getHostName(defaultHostname);
    if (hostname && CORE_API_BASE_URL_MAP[hostname]) {
        return `https://${hostname}`;
    }
    return process.env.CORE_WEB_BASE_URL;
};

export const getProfileUrl = () => {
    return `${SCREEN_PASS_URL}/account?apiKey=${
        window.gigya?.apiKey || process.env.GIGYA_PUBLIC_API_KEY
    }&redirectUrl=${getWebBaseUrl()}`;
};
