export const getStoredValue = (key: string) => {
    if (typeof window !== "undefined" && window.sessionStorage) {
        try {
            const storedValue = window.sessionStorage.getItem(key);
            if (storedValue) {
                return JSON.parse(storedValue);
            }
        } catch (err) {
            // console.log(err);
        }
    }
    return null;
};

export const storeValue = (key: string, value: any) => {
    if (value && typeof window !== "undefined" && window.sessionStorage) {
        let valueString = "";
        try {
            valueString = JSON.stringify(value);
            window.sessionStorage.setItem(key, valueString);
        } catch (err) {
            // console.log(err);
        }
    }
};

export const removeStoredValue = (key: string) => {
    if (key && typeof window !== "undefined" && window.sessionStorage) {
        try {
            window.sessionStorage.removeItem(key);
        } catch (err) {
            // console.log(err)
        }
    }
};
