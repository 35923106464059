export const AD_NETWORK_ID = "4595";
export const AD_ACCOUNT_ID =
    process.env.APP_ENV === "staging"
        ? "fantasy.nfl.test.com"
        : "fantasy.nfl.com";
const AD_BASE_PATH = `/${AD_NETWORK_ID}/${AD_ACCOUNT_ID}`;
export const AD_UNIT_HOME = `${AD_BASE_PATH}/home`;
export const AD_UNIT_LANDING = `${AD_BASE_PATH}/landing`;
export const TRACKING_HOME = "home";
export const TRACKING_LANDING = "play fantasy";
export const TRACKING_SITE_NAME = "nfl fantasy";
export const FANTASY_PLUS_ACCESS_POINT = "Fantasy Plus Access Point";
export const FANTASY_PLUS_PURCHASE_ACCESS_POINT =
    "Fantasy Plus Purchase Access Point";
export const NFL_PLUS_ACCESS_POINT = "NFL Plus Access Point";
export const NFL_PLUS_PURCHASE_ACCESS_POINT = "NFL Plus Purchase Access Point";
