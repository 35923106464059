export const FEATURES = {
    AD_TEST: "ad.test",
    DRAFT_PICK_EVALUATOR: "draft.pick.evaluator",
    FANTASYPLUS: "fantasyplus",
    FANTASYPLUS_DRAFT: "fantasyplus.draft",
    FANTASYPLUS_DRAFT_UPSELL: "fantasyplus.draft.upsell",
    FANTASYPLUS_NAVITEM: "fantasyplus.navitem",
    FANTASYPLUS_UPSELL: "fantasyplus.upsell",
    GENIUS_HOME: "genius.home",
    PRIVACY_ONETRUST: "privacy.onetrust",
    PURCHASE_BYPASS: "purchase.bypass",
    PURCHASE_RECURLY: "purchase.recurly",
    REFRESHABLE_TOKEN: "refreshable.token",
} as const;

export type FeatureType = (typeof FEATURES)[keyof typeof FEATURES];
