import {CONFIG_KEYS} from "@nfl/rn-shared/src/constants/config";
import {isLeagueSiteDev} from "@nfl/rn-shared/src/utils/isLeagueSiteDev";

import {NFLAPIConfig} from "@nfl/nfl-api/src/NFLAPIConfig/NFLAPIConfig";
import {axiosInstance} from "@nfl/nfl-api/src/axiosInstance";
import {
    GigyaAddEventHandlers,
    GigyaGetAccountInfo,
    GigyaLogin,
    GigyaLoginProvider,
} from "./types";

import {awaitGigyaReady} from "./onGigyaServiceReadyHandler";

const {CURRENT_PRIVACY_POLICY, CURRENT_SUBSCRIPTION_TERMS, CURRENT_TERMS} =
    CONFIG_KEYS;

function parseValidationErrors(
    errors: Array<{
        customMessage: string;
        errorCode: number;
        fieldName: string;
        message: string;
    }>
): {
    [key: string]: string;
} {
    const registrationFieldNameMap = {
        email: "email",
        "profile.birthDay": "birthday",
        "profile.firstName": "firstName",
        "profile.lastName": "lastName",
        "profile.zip": "zip",
        password: "password",
    };
    return errors.reduce((errorDictionary, error) => {
        return {
            ...errorDictionary,
            ...(registrationFieldNameMap[error.fieldName]
                ? {
                      [registrationFieldNameMap[error.fieldName]]:
                          error.message ?? error.customMessage,
                  }
                : {}),
        };
    }, {});
}

// should catch the following:
//      - invalid strings
//      - password too short
//      - username special characters
//      - special characters in first name / last name
//      - weird first/last names
//      - birthday too young
//      - email not valid email format
//
// 400006 - Invalid parameter value
// 403044 - Underage user
// 400009 - Validation
export function parseCreateAccountErrorByCode(code: string, message?: any) {
    if (code === "400006") {
        const stringDescription = {
            email: "email",
            birth: "birthday",
        };

        const errors = {};
        Object.keys(stringDescription).forEach((key: string) => {
            const {errorDetails} = message || {};
            if (errorDetails?.toLowerCase().includes(key)) {
                const fieldName = stringDescription[key];
                if (!!fieldName) {
                    errors[fieldName] = errorDetails;
                }
            }
        });
        return errors;
    }
    if (code === "403044") {
        return {
            birthday: "Sorry, we are not able to process your registration.",
        };
    }
    if (code === "400009") {
        const {validationErrors} = message || {};
        if (validationErrors) {
            return parseValidationErrors(validationErrors);
        }
    }

    return {
        unknownError: `Unknown error occurred. Please try again later. Code: ${code}`,
    };
}

export function parseCreateAccountError(error: any) {
    return parseCreateAccountErrorByCode(
        `${error.code}`,
        JSON.parse(error.message)
    );
}

export function parseLoginErrorCodeByCode(code: string, message?: any) {
    const defaultError = "Invalid loginID or password";
    const lockedOutError =
        "There appears to be an issue with accessing your account.  If you are on Wi-Fi, try logging in with it disabled.";
    const knownError = code === "403042" || code === "403043";
    const getErrorMessage = (errorCode) => {
        if (knownError) {
            return `${message?.errorDetails} (Code: ${errorCode})`;
        }
        if (errorCode === "403120") {
            return "";
        }

        return defaultError;
    };
    const error = getErrorMessage(code);
    return {
        email: error,
        password: error,
        knownError,
        errorCode: code,
        loginError:
            code === "403120" ? `${lockedOutError} (Code: ${code})` : "",
    };
}

export function parseLoginError(error: any) {
    let message;
    try {
        message = JSON.parse(error.message);
    } catch (e) {
        // do nothing
    }
    return parseLoginErrorCodeByCode(error.code, message);
}

export function parseUpdateUserErrorByCode(code: string, message?: any) {
    if (code === "400006" || code === "400009") {
        const {validationErrors} = message || {};
        if (validationErrors) {
            return parseValidationErrors(validationErrors);
        }
    }
    if (code === "400003") {
        return {
            username: message?.errorDetails || "username already exists",
        };
    }
    return {
        unknownError: `Unknown error occurred. Please try again later. Code: ${code}`,
    };
}

export function parseUpdateUserError(error: any) {
    let {message} = error;
    try {
        message = JSON.parse(message);
    } catch {
        // do nothing
    }
    return parseUpdateUserErrorByCode(`${error.code}`, message);
}

export const getSocialAuthMeta = (gigyaData?: GigyaAddEventHandlers) => {
    if (gigyaData && "newUser" in gigyaData && "provider" in gigyaData) {
        const {provider, newUser} = gigyaData;
        const isSocial = provider !== GigyaLoginProvider.SITE;

        return {
            isSocial,
            isSocialLogin: isSocial && !newUser,
            isSocialReg: isSocial && newUser,
        };
    }

    return {
        isSocial: false,
        isSocialLogin: false,
        isSocialReg: false,
    };
};

export const getGigyaLoginStatus = async () => {
    await awaitGigyaReady();
    return new Promise<boolean>((resolve) => {
        (globalThis as any).gigya.accounts.session.verify({
            callback: (gigyaData) => {
                const errorCode = Number(gigyaData.errorCode);
                if (errorCode === 0) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            },
        });
    });
};

export const getIsConsentRequired = ({
    bootstrap,
    consentProps = [
        CURRENT_PRIVACY_POLICY,
        CURRENT_SUBSCRIPTION_TERMS,
        CURRENT_TERMS,
    ],
    gigyaData,
}: {
    bootstrap?: {[key: string]: string} | null;
    consentProps?: string[];
    gigyaData?: GigyaLogin | GigyaGetAccountInfo;
}) => {
    const consentRequiredFor: string[] = [];

    const {preferences} = gigyaData || {};

    if (!preferences) {
        // eslint-disable-next-line no-console
        console.warn("Prefences object was not included on Gigya response.");
        return {isConsentRequired: false, consentRequiredFor};
    }

    const privacyKey = bootstrap?.[CURRENT_PRIVACY_POLICY] || "";
    const termsKey = bootstrap?.[CURRENT_TERMS] || "";
    const subTermsKey = bootstrap?.[CURRENT_SUBSCRIPTION_TERMS] || "";

    if (!privacyKey || !termsKey || !subTermsKey) {
        // eslint-disable-next-line no-console
        console.warn("One or more of the consent keys was not defined");
    }

    /**
     * Preferences object for some accounts have a different data structure
     * where keys are prefixed with the name of what is otherwise a parent object.
     * Example: termsParent: { termsChild: {...} } becomes termsParent.termsChild: {...}
     */
    if (consentProps.includes(CURRENT_PRIVACY_POLICY)) {
        if (
            !preferences?.privacy?.[privacyKey]?.isConsentGranted &&
            !preferences?.[`privacy.${privacyKey}`]?.isConsentGranted
        ) {
            consentRequiredFor.push(CURRENT_PRIVACY_POLICY);
        }
    }
    if (consentProps.includes(CURRENT_TERMS)) {
        if (
            !preferences?.terms?.[termsKey]?.isConsentGranted &&
            !preferences?.[`terms.${termsKey}`]?.isConsentGranted
        ) {
            consentRequiredFor.push(CURRENT_TERMS);
        }
    }
    if (consentProps.includes(CURRENT_SUBSCRIPTION_TERMS)) {
        if (
            !preferences?.terms?.[subTermsKey]?.isConsentGranted &&
            !preferences?.[`terms.${subTermsKey}`]?.isConsentGranted
        ) {
            consentRequiredFor.push(CURRENT_SUBSCRIPTION_TERMS);
        }
    }

    if (isLeagueSiteDev()) {
        // eslint-disable-next-line no-console
        console.log(JSON.stringify({consentRequiredFor, preferences}, null, 2));
    }

    return {
        isConsentRequired: consentRequiredFor.length > 0,
        consentRequiredFor,
    };
};

export const getAccountPreferencesObject = ({
    bootstrap,
    consentProps = [
        CURRENT_PRIVACY_POLICY,
        CURRENT_SUBSCRIPTION_TERMS,
        CURRENT_TERMS,
    ],
}: {
    bootstrap?: {[key: string]: string} | null;
    consentProps?: string[];
}) => {
    const privacyKey = bootstrap?.[CURRENT_PRIVACY_POLICY] || "";
    const termsKey = bootstrap?.[CURRENT_TERMS] || "";
    const subTermsKey = bootstrap?.[CURRENT_SUBSCRIPTION_TERMS] || "";

    if (!privacyKey || !termsKey || !subTermsKey) {
        // eslint-disable-next-line no-console
        console.warn("One or more of the consent keys was not defined");
    }

    const isConsentGranted = true;

    const preferences: {
        privacy?: {[key: string]: {isConsentGranted: true}};
        terms?: {[key: string]: {isConsentGranted: true}};
    } = {};

    if (consentProps.includes(CURRENT_PRIVACY_POLICY)) {
        preferences.privacy = {[privacyKey]: {isConsentGranted}};
    }
    if (consentProps.includes(CURRENT_TERMS)) {
        preferences.terms = {[termsKey]: {isConsentGranted}};
    }
    if (consentProps.includes(CURRENT_SUBSCRIPTION_TERMS)) {
        /** This if/else makes sure we dont overwrite the work done in the
         * previous terms condition */
        if (!preferences.terms) {
            preferences.terms = {
                [subTermsKey]: {isConsentGranted},
            };
        } else {
            preferences.terms[subTermsKey] = {isConsentGranted};
        }
    }

    if (isLeagueSiteDev()) {
        // eslint-disable-next-line no-console
        console.log(JSON.stringify({preferences}, null, 2));
    }

    return preferences;
};

export const isLoginIDAvailable = async ({
    loginID,
}: {
    loginID: string;
}): Promise<boolean> => {
    await awaitGigyaReady();
    return new Promise((resolve) => {
        (global as any).gigya.accounts.isAvailableLoginID({
            loginID,
            callback: (res: {isAvailable?: boolean}) => {
                resolve(!!res.isAvailable);
            },
        });
    });
};

export async function fetchSignature({
    uid,
    token,
}: {
    uid: string;
    token: string;
}) {
    // ${UID} is for the server side - https://github.dm.nfl.com/nfl/be-id-auth-manager#v2utilitiesgigyasignature
    /* eslint-disable-next-line no-template-curly-in-string */
    const query = "SELECT * FROM watchHistory WHERE UID='${UID}'";
    const timestamp = Date.now() + 1000 * 60 * 60;

    const {baseUrl} = NFLAPIConfig;
    const computedUrl = `${baseUrl}/identity/v2/utilities/gigyaSignature?query=${encodeURIComponent(
        query
    )}&timestamp=${timestamp}&uid=${encodeURIComponent(uid)}`;
    const init = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
        method: "GET",
    };

    const {querySig} = await axiosInstance
        .request({
            url: computedUrl,
            ...init,
        })
        .then((response) => {
            return response?.data;
        })
        .catch(() => {
            return null;
        });

    return {querySig, timestamp: String(timestamp)};
}
