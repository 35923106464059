export const CORE_API_BASE_URL_MAP = JSON.parse(
    process.env.CORE_API_BASE_URL_MAP || "{}"
);

export const PUBLIC_CONFIG_URL = "https://api.nfl.com/v1/config?c=/public/web";

export const SCREEN_PASS_URL =
    process.env.APP_ENV === "production"
        ? "https://id.nfl.com"
        : "https://staging-id.nfl.com";

export const ENDPOINTS = {
    GAME_STATE: "/v2/game/state",
    GET_LEAGUE_SETTINGS: "/v2/league/settings",
    GET_PLAYERS_SEARCH: "/v2/players/autocomplete",
    GET_USER: "/v2/user",
    GET_USER_LEAGUES: "/v2/user/leagues",
    SITE_INFO: "/v3/sites",
};
