import fetch from "isomorphic-unfetch";

import {PUBLIC_CONFIG_URL} from "../constants/api";
import {log} from "../helpers/debug";
// utils
import {getCookieValue} from "./cookie";
import {getFantasyApiUrl, parseQuery} from "./url";

type FetchOptions = {
    body?: string | FormData;
    method?: "GET" | "POST" | "PUT" | "DELETE";
    token?: any;
};

export async function fetchNflApi(path: string, options: FetchOptions = {}) {
    try {
        const {token, ...fetchOptions} = options;

        const res = await fetch(`${process.env.NFL_API_PATH}${path}`, {
            ...fetchOptions,
            headers: {
                ...(token && {
                    Authorization: `Bearer ${token.accessToken}`,
                }),
            },
        });
        if (!res.ok) {
            return null;
        }
        const json = await res.json();
        return json;
    } catch (err) {
        return null;
    }
}

export async function fetchNflContentApi(
    path: string,
    options: FetchOptions = {}
) {
    try {
        const {token, ...fetchOptions} = options;

        const res = await fetch(`${process.env.CONTENT_API_URL}${path}`, {
            ...fetchOptions,
            headers: {
                ...(token && {
                    Authorization: `Bearer ${token.accessToken}`,
                }),
            },
        });
        if (!res.ok) {
            return null;
        }
        const json = await res.json();

        return {
            ...json,
            items: json.items.map((item) => ({
                contentType: item.type,
                headline: item.mobileTitle || item.title,
                shortHeadline: item.title,
                url: item.webLink,
                imageUrl: item.thumbnail?.thumbnailUrl || null,
                caption: item.description,
                duration: item.duration,
                type: item.type,
                ctaLink: item.ctaLink,
                ctaText: item.ctaText,
            })),
        };
    } catch (err) {
        return null;
    }
}

export async function fetchNflFooter() {
    try {
        const payloads = await Promise.all([
            fetch("https://www.nfl.com/_libraries/fragments/assets")
                .then((res) => res.text())
                .then((text) => text.replace(/window\.gigya[^;]*;/g, ""))
                .then((text) =>
                    text.replace(
                        /"\/compiledassets/g,
                        '"https://www.nfl.com/compiledassets'
                    )
                ),
            fetch(
                "https://www.nfl.com/_libraries/fragments/footer-fragment"
            ).then((res) => res.text()),
        ]);
        return payloads.join("");
    } catch (err) {
        log(err);
        return "";
    }
}

type FantasyFetchOptions = FetchOptions & {
    firstGame?: boolean;
    query?: {[key: string]: any};
};

export async function fetchFantasyApi(
    path: string,
    options: FantasyFetchOptions = {}
) {
    try {
        const {token, firstGame, query, ...fetchOptions} = options;
        const appKey = process.env.FANTASY_APP_KEY;
        const queryString = Object.entries({...query, appKey})
            .map(([key, value]) =>
                value == null ? "" : `&${key}=${encodeURIComponent(value)}`
            )
            .join("")
            .slice(1);
        const headers: HeadersInit = {};
        if (token) {
            headers.Authorization = `Bearer ${token.accessToken}`;
            const impersonateId = parseQuery(getCookieValue("fana")).iu;
            if (impersonateId) {
                headers["X-NFL-Fantasy-Impersonate-User"] = impersonateId;
            }
        }
        const res = await fetch(`${getFantasyApiUrl()}${path}?${queryString}`, {
            ...fetchOptions,
            headers,
        });
        if (!res.ok) {
            return null;
        }
        const json = await res.json();
        return firstGame ? json.games[Object.keys(json.games)[0]] : json;
    } catch (err) {
        return null;
    }
}

export async function fetchPublicInfo() {
    try {
        const res = await fetch(PUBLIC_CONFIG_URL);
        const body = await res.json();
        return {
            body,
            headers: {
                nfl_geo_country: res.headers.get("nfl_geo_country"),
                nfl_geo_dma: res.headers.get("nfl_geo_dma"),
                nfl_geo_zip: res.headers.get("nfl_geo_zip"),
            },
        };
    } catch (err) {
        return null;
    }
}

export function removeUndefinedForNextJsSerializing<T>(props: T): T {
    if (props && typeof props === "object") {
        return Object.fromEntries(
            Object.entries(props).filter(([, value]) => value !== undefined)
        ) as T;
    }
    return props;
}
