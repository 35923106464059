import {Dimensions, PixelRatio, Platform} from "react-native";
import DeviceInfo from "react-native-device-info";
import {UAParser} from "ua-parser-js";

import {mmkvStorageWrapper} from "../stores/localStorage";
import {
    AudioGuidanceStatus,
    CTVDevices,
    DeviceInfoState,
} from "../types/deviceInfoTypes";
import {getConnectionType} from "./getConnectionType";
// utils
import {timeout as timeoutPromise} from "./promise";

type DeviceType = "Tablet" | "MobilePhone" | "Tv";

const DEVICE_INFO_KEY = "nfl.authutils.deviceinfo";

const webOS = (globalThis as any)?.webOS;

// TODO: Move this into Connected and figure out how to use the correct package import
let systeminfo;
let tvinfo;

// @ts-ignore
if (typeof window !== "undefined" && window?.webapis) {
    // @ts-ignore
    systeminfo = window?.webapis?.systeminfo;
    // @ts-ignore
    tvinfo = window?.webapis?.tvinfo;
}

// Hack react-native module accessors for jest tests.
// Works around the following error:
// ReferenceError: You are trying to `import` a file after the Jest environment has been torn down.
const isWeb = Platform.OS === "web";

// TODO: We should move this into Connected and potentially remove this file/keep a base version
export const getDeviceInfo = async (
    useCachedValue = false
): Promise<DeviceInfoState> => {
    let parser;
    let parseResult;

    if (isWeb) {
        parser = new UAParser();
        parser.setUA(window.navigator.userAgent ?? "");
        parseResult = parser.getResult();
    }

    let deviceType: DeviceType = DeviceInfo.isTablet()
        ? "Tablet"
        : "MobilePhone";
    const {width, height} = Dimensions.get("screen");
    const displayWidth = PixelRatio.getPixelSizeForLayoutSize(width);
    const displayHeight = PixelRatio.getPixelSizeForLayoutSize(height);

    if (useCachedValue && !isWeb) {
        const storedDeviceInfo = mmkvStorageWrapper.getItem(DEVICE_INFO_KEY);
        try {
            if (storedDeviceInfo) {
                const parsedDeviceInfo = JSON.parse(storedDeviceInfo);
                return {
                    ...parsedDeviceInfo,
                    displayHeight,
                    displayWidth,
                };
            }
        } catch (e) {
            // no-op
            // lets just continue to recalculate the device info
        }
    }

    let manufacturer = !isWeb
        ? await DeviceInfo.getManufacturer()
        : parseResult.device.vendor;
    const isFireTV = !isWeb
        ? await DeviceInfo.hasSystemFeature("amazon.hardware.fire_tv").then(
              (hasFeature) => hasFeature
          )
        : false;

    const isLocationEnabled = await DeviceInfo?.isLocationEnabled();
    const totalMemory = await DeviceInfo?.getTotalMemory();
    const totalDiskCapacity = await DeviceInfo?.getTotalDiskCapacity();

    let ctvDevice = "AppleTV";
    let isAndroidMobileWeb = false;

    if (isWeb) {
        const urlSearchParams = new URLSearchParams(window.location.search);
        ctvDevice = urlSearchParams.get("device") ?? "";

        isAndroidMobileWeb = parseResult?.os.name === "Android";
    } else {
        const systemName = DeviceInfo.getSystemName();
        if (systemName === "Android") {
            ctvDevice = "AndroidTV";
        }
    }

    if (isAndroidMobileWeb) {
        manufacturer = "Android";
        parseResult.device.model = `Android ${
            DeviceInfo.isTablet() ? "Tablet" : "Phone"
        }`;
    }

    if (isFireTV) {
        ctvDevice = "FireTV";
    }

    if (ctvDevice === "lg") {
        try {
            deviceType = "Tv";

            // @TODO investigate better import for LG types - isolated types are not being exported from the lib
            const promisifyDeviceInfo = () =>
                new Promise((resolve) => webOS?.deviceInfo(resolve));
            const lgDeviceInfo: any = await timeoutPromise(
                promisifyDeviceInfo(),
                500
            );

            const audioGuidanceRequest = () =>
                new Promise((resolve) =>
                    webOS?.service.request("luna://com.webos.settingsservice", {
                        method: "getSystemSettings",
                        parameters: {
                            category: "option",
                            keys: ["audioGuidance"],
                        },
                        onSuccess(inResponse: any) {
                            resolve(inResponse?.settings?.audioGuidance);
                        },
                    })
                );

            const audioGuidanceStatus: any = await timeoutPromise(
                audioGuidanceRequest(),
                300
            );
            manufacturer = "LG";
            parseResult.device.audioGuidanceStatus = audioGuidanceStatus;
            parseResult.device.ddrSize = lgDeviceInfo.ddrSize;
            parseResult.device.model = "LG TV";
            parseResult.device.vendor = "LG Electronics";
            parseResult.os.name = "WEBOS";
            parseResult.os.version = lgDeviceInfo.sdkVersion;
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error("Error fetching WebOS device info", error);
        }
    }

    if (ctvDevice === "samsung") {
        deviceType = "Tv";
        manufacturer = "Samsung";
        // https://developer.samsung.com/smarttv/develop/guides/fundamentals/text-to-speech.html
        parseResult.device.audioGuidanceStatus = tvinfo?.getMenuValue(
            tvinfo?.TvInfoMenuKey?.VOICE_GUIDE_KEY || ""
        )
            ? "on"
            : "off";
        parseResult.device.model = "Samsung TV";
        parseResult.os.version = systeminfo?.getVersion();
    }

    if (ctvDevice === "vizio") {
        deviceType = "Tv";
        manufacturer = "Vizio";
        parseResult.device.model = "Vizio TV";
        parseResult.device.vendor = "Vizio";
        parseResult.device.audioGuidanceStatus =
            // @ts-expect-error
            window?.VIZIO?._ttsEnabled
                ? AudioGuidanceStatus.ON
                : AudioGuidanceStatus.OFF;
    }

    if (ctvDevice === "xbox") {
        deviceType = "Tv";
        manufacturer = "Microsoft";
        parseResult.device.model = "XBOX";
    }

    const appNameMappings: Record<CTVDevices, string> = {
        AndroidTV: "Android TV",
        AppleTV: "Apple TV",
        FireTV: "Fire TV",
        lg: "LGTV",
        samsung: "SamsungTV",
        vizio: "VizioTV",
        xbox: "Xbox",
    };

    const analyticsAppName = appNameMappings?.[ctvDevice || ""] || "";

    const deviceInfo = {
        deviceInfo: {
            analyticsAppName,
            capabilities: {
                audioGuidance: parseResult?.device?.audioGuidanceStatus,
            },
            ctvDevice: ctvDevice as CTVDevices,
            displayHeight,
            displayWidth,
            networkType: await getConnectionType(),
            memory: totalMemory,
            ddrSize: parseResult?.device?.ddrSize,
            diskCapacity: totalDiskCapacity,
            idfv: await DeviceInfo.getUniqueId(), // https://github.com/react-native-device-info/react-native-device-info#getuniqueid
            isLocationEnabled,
            manufacturer: manufacturer?.replace(" ", "_"),
            model: !isWeb
                ? DeviceInfo.getModel().replace(" ", "_")
                : parseResult.device.model,
            osName: !isWeb
                ? DeviceInfo.getSystemName().replace(" ", "_")
                : parseResult.os.name,
            osVersion: !isWeb
                ? DeviceInfo.getSystemVersion().replace(" ", "_")
                : parseResult.os.version,
            vendor: !isWeb
                ? DeviceInfo.getBrand().replace(" ", "_")
                : parseResult.device.vendor,
            version: !isWeb
                ? DeviceInfo.getDeviceId().replace(" ", "_")
                : parseResult.browser.name,
            versionName: !isWeb
                ? DeviceInfo.getReadableVersion()
                : parseResult.os.version,
        },
        deviceType,
    };

    // save in memory
    mmkvStorageWrapper.setItem(DEVICE_INFO_KEY, JSON.stringify(deviceInfo));

    return deviceInfo;
};
