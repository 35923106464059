import * as React from "react";
import type {FeatureType} from "../constants/features";
import {useFlagsmith} from "./useFlagsmith";

const FEATURES_PREFIX = "features.";

export const useFeatureFlags = (flagOrFlags: FeatureType | FeatureType[]) => {
    const flagKeys = React.useMemo(() => {
        return Array.isArray(flagOrFlags)
            ? flagOrFlags.map((flag) => `${FEATURES_PREFIX}${flag}`)
            : [`${FEATURES_PREFIX}${flagOrFlags}`];
    }, [flagOrFlags]);

    const {flagsmith, flagsmithReady} = useFlagsmith();

    const result = React.useMemo(() => {
        const flags = flagsmithReady ? flagsmith.getAllFlags() : {};
        const regex = new RegExp(`^${FEATURES_PREFIX}`);
        return flagKeys.reduce(
            (acc, flagKey) => {
                acc[flagKey.replace(regex, "")] =
                    flags[flagKey]?.enabled || false;
                return acc;
            },
            {} as {[key in FeatureType]: boolean}
        );
    }, [flagKeys, flagsmith, flagsmithReady]);

    return result;
};
