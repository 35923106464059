// naming convention taken from https://davidwalsh.name/sass-color-variables-dont-suck
// names generated from http://chir.ag/projects/name-that-color/
export const COLORS = {
    ALABASTER: "#F7F7F7",
    ATHENS_GRAY: "#F2F2F3",
    BLACK: "#000000",
    BLUE_RIBBON: "#004FFF",
    BOULDER: "#757575",
    BRIGHT_TURQUOISE: "#00CCF5",
    BUDDAH_GOLD: "#BD9D00",
    CAPE_COD: "#323434",
    CARARRA: "#E9E8E3",
    CERULEAN: "#00AACC",
    CHAMPAGNE: "#FADBD0",
    CHARTREUSE: "#88FF00",
    CHATEAU_GREEN: "#42B359",
    CLOUD: "#CECCC5",
    COD_GRAY: "#151515",
    CORN: "#E6BF00",
    CYPRUS: "#00333D",
    DEEP_CERULEAN: "#0088A3",
    DESERT_STORM: "#EEEDEB",
    DODGER_BLUE: "#3372FF",
    DRIFTWOOD: "#B1924F",
    DUNE: "#242424",
    EMPEROR: "#555555",
    FIRE: "#BD3200",
    FLUSH_ORANGE: "#FF8000",
    FUN_GREEN: "#008A45",
    GALLERY: "#EBEBEB",
    GEYSER: "#D4DBE3",
    GOLD: "#FFD500",
    GHOST: "#C6CBD7",
    GRAY: "#858585",
    GRAY_NICKEL: "#BAB9B3",
    HAMPTON: "#E6DCAE",
    ICEBERG: "#D2ECF2",
    JADE: "#00B359",
    LIMEADE: "#3CB200",
    MANHATTAN: "#F4B19A",
    MANGO_TANGO: "#E67300",
    MERCURY: "#E8E8E8",
    MIDNIGHT: "#011A36",
    MIDNIGHT_BLUE: "#013369",
    MIDNIGHT_DARK: "#01152B",
    MINE_SHAFT: "#333333",
    MISCHKA: "#D7DBE0",
    MYSTIC: "#DAE1E9",
    NOBEL: "#B8B8B8",
    OREGON: "#992900",
    ORIENT: "#00667A",
    PICTON_BLUE: "#38A1F3",
    POLAR: "#E6F7FA",
    PUNCH: "#D93E22",
    PURPLE_PIZZAZZ: "#FF00CC",
    QUILL_GRAY: "#DCDCD5",
    RIPE_LEMON: "#F4D60C",
    ROB_ROY: "#E7C170",
    ROSE_OF_SHARON: "#BD5E00",
    SCIENCE_BLUE: "#086AC4",
    SCORPION: "#5E5E5E",
    SHARK: "#1F1F1F",
    SHARK_DARK: "#1D1D1D",
    SHERPA_BLUE: "#004452",
    SILVER: "#CCCCCC",
    SILVER_CHALICE: "#AAAAAA",
    SPRING_GREEN: "#00DB6E",
    TIMBERWOLF: "#D5D3CC",
    TRANSPARENT: "transparent",
    TRINIDAD: "#E63D00",
    TUNDORA: "#454545",
    VENICE_BLUE: "#064d8e",
    VERMILION: "#FF4400",
    WESTAR: "#DEDCD5",
    WILD_SAND: "#F5F5F5",
    WHITE: "#FFFFFF",
    YELLOW: "#FAFF00",
};
