import * as React from "react";

import {defaultUserState} from "../utils/user";

// types
import type {UserType} from "../types";

type UserContextType = {
    user: UserType;
    fetched: boolean;
    refresh: () => Promise<void>;
};

export default React.createContext<UserContextType>({
    user: defaultUserState,
    fetched: false,
    refresh: async () => {},
});
