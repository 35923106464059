import {Gigya} from "@nfl/expo-module-gigya/src/Gigya";
import {NFLAPIConfig} from "@nfl/nfl-api/src/NFLAPIConfig/NFLAPIConfig";
import {sha256} from "js-sha256";

// constants
import {getNFLPlusOrPlusPremiumOrPlusPlusStatusByToken} from "@nfl/rn-shared/src/utils/getUserStatus";
import {ENDPOINTS} from "../constants/api";

// types
import type {UserType} from "../types";

import {
    FANTASY_PLUS_PLAN,
    NFL_PLUS_PLAN,
    NFL_PLUS_PLUS_PLAN,
    NFL_PLUS_PREMIUM_PLAN,
} from "../constants/plans";
import {getCookieName, getCookieValue, removeFantasyCookie} from "./cookie";
// utils
import {getNFLAuthTokenConfig, removeNFLAuthToken} from "./getNFLToken";
import {getGigyaAccount, submitGigyaLoginForm} from "./gigya";
import {fetchFantasyApi} from "./sdk";
import {getStoredValue, storeValue} from "./sessionStorage";
import {getWebBaseUrl, parseQuery} from "./url";

let userLeaguesResponse: any; // TODO: remove

export const getSelectedLeague = (leagues?: any[], leagueId?: string) => {
    return (
        (leagueId && leagues?.find(({id}) => `${id}` === leagueId)) ||
        leagues?.[0]
    );
};

export async function getUser() {
    await Gigya.initDefault();

    const gigyaAccount = await getGigyaAccount().catch(() => {});
    const uid = gigyaAccount?.UID;

    try {
        if (!uid) {
            throw new Error("User not logged in (gigya)");
        }

        try {
            localStorage.setItem(
                "nfl.user",
                JSON.stringify({
                    data: gigyaAccount.data ?? "",
                    gigyaUID: uid,
                    gigyaUIDSignature: gigyaAccount.UIDSignature ?? "",
                    hashedEmail: sha256(gigyaAccount.profile?.email ?? ""),
                    exp: Date.now() + 60000,
                })
            );
        } catch (e) {
            // ignore
        }

        const cookieUID = parseQuery(getCookieValue(getCookieName()))?.uid;

        if (uid !== cookieUID) {
            // reset ff cookie
            await submitGigyaLoginForm(gigyaAccount);
        }

        NFLAPIConfig.register({
            articleBaseUrl: null,
            baseUrl: process.env.NFL_API_PATH,
            getAuthToken: getNFLAuthTokenConfig({
                userState: {
                    nfl: {
                        gigyaUID: uid,
                        gigyaUIDSignature: gigyaAccount.UIDSignature,
                        signatureTimestamp: gigyaAccount.signatureTimestamp,
                    },
                    status: "USER_LOGIN_SUCCESS",
                },
            }),
        });

        const token = await NFLAPIConfig.getAuthToken();
        const user = await fetchFantasyApi(ENDPOINTS.GET_USER, {token});

        const hasNflPlusOrPremium =
            getNFLPlusOrPlusPremiumOrPlusPlusStatusByToken(token);

        if (!user) {
            throw new Error("User not logged in (fantasy)");
        }

        const {loginUser, systemConfig} = user;
        return {loginUser, hasNflPlusOrPremium, systemConfig, token, uid};
    } catch (e) {
        removeFantasyCookie();
        await removeNFLAuthToken();
        localStorage.removeItem("nfl.user");
        return {
            loginUser: {
                isUserLoggedIn: !!uid,
                nflUser: {emailAddress: gigyaAccount?.profile?.email},
            },
            uid,
        };
    }
}

export async function getUserLeagues(userId: string) {
    const token = await NFLAPIConfig.getAuthToken();
    const response = await fetchFantasyApi(ENDPOINTS.GET_USER_LEAGUES, {token});
    userLeaguesResponse = response;
    const gameId = Object.keys(response.games)[0];
    const user = response.users[userId].games[gameId];
    const {leagues, state} = response.games[gameId];
    const coreWebBaseUrl = getWebBaseUrl();

    return {
        isOverMaxTeams: user.isOverMaxTeams,
        leagues: user.leagueIds.map((leagueId) => {
            const league = leagues[leagueId];
            const teamId = Object.keys(league.teams)[0];
            const team = league.teams[teamId];
            return {
                id: parseInt(leagueId, 10),
                name: league.name,
                url: `${coreWebBaseUrl}/league/${leagueId}`,
                teamUrl: `${coreWebBaseUrl}/league/${leagueId}/team/${teamId}`,
                teamId,
                teamName: team.name,
                teamLogo: team.imageUrl,
                teamRank: team.rank,
                numTeams: league.numTeams,
                draftStatus: league.draftStatus,
                isManager: league.leagueManagerUserIds.includes(
                    team.ownerUserId
                ),
                isExperience: league.isExperience,
                leagueType: league.leagueType,
                upgradeable: false,
                canUpgradeLeague: false,
            };
        }),
        gameState: state,
        week: state.week,
    };
}

const userKey = "fantasy-user";

export function getInitialUserState(): UserType {
    return getStoredValue(userKey);
}

export async function getUserState(): Promise<UserType> {
    const user = await getUser();
    const {loginUser, hasNflPlusOrPremium, token} = user;
    const {fantasyUser, isImpersonatingUser, isUserLoggedIn, nflUser} =
        loginUser;
    const {hasAdminPrivileges, userId, isFantasyPlusPackageUser} =
        fantasyUser || {};
    const systemConfig =
        user?.systemConfig ||
        (await fetchFantasyApi(ENDPOINTS.GAME_STATE))?.systemConfig ||
        {};
    const userLeagueData: Partial<UserType> = userId
        ? await getUserLeagues(userId)
        : {};

    const userData = {
        isOverMaxTeams: !!userLeagueData?.isOverMaxTeams,
        isLoggedIn: isUserLoggedIn,
        isImpersonatingUser,
        hasAdminPrivileges,
        email: nflUser.emailAddress ?? "",
        userId,
        systemConfig,
        gameState: userLeagueData?.gameState,
        leagues: userLeagueData?.leagues || [],
        hasNflPlusOrPremium,
        plans: token?.plans || [],
        week: userLeagueData?.week,
        isFantasyPlusPackageUser,
    };
    storeValue(userKey, userData);
    return userData;
}

export function getUserLeaguesResponse() {
    return userLeaguesResponse;
}

export function userHasFantasyPlus(user: UserType) {
    const {plans, isFantasyPlusPackageUser} = user ?? {};
    return (
        plans.some(
            (plan) =>
                plan.plan === FANTASY_PLUS_PLAN ||
                plan.plan === NFL_PLUS_PLAN ||
                plan.plan === NFL_PLUS_PREMIUM_PLAN ||
                plan.plan === NFL_PLUS_PLUS_PLAN
        ) || isFantasyPlusPackageUser
    );
}

export const defaultUserState = {
    email: null,
    leagues: [],
    plans: [],
    isLoggedIn: false,
    gameState: {},
    systemConfig: {},
    isImpersonatingUser: false,
    isOverMaxTeams: false,
    hasAdminPrivileges: false,
};
