import flagsmith from "flagsmith";
import * as React from "react";

export const useFlagsmith = () => {
    const [flagsmithReady, setFlagsmithReady] = React.useState(false);

    React.useEffect(() => {
        const areFlagsLoaded = !!flagsmith.getAllFlags();
        if (areFlagsLoaded) {
            setFlagsmithReady(true);
            return;
        }

        let environmentID;
        let isProduction = false;
        if (process.env.APP_ENV === "production") {
            environmentID = "hCAgroDSVknU4cnnSniFgA";
            isProduction = true;
        } else if (process.env.APP_ENV === "staging") {
            environmentID = "8eKLLN4GdhnBQuWebUcgtL";
        } else {
            environmentID = "M8Y3YysovdHqGHQ6cYzqvo";
        }

        flagsmith
            .init({
                api: "https://flags.api.nfl.com/api/v1/",
                cacheFlags: true,
                cacheOptions: {
                    ttl: isProduction ? 300000 : 0,
                    skipAPI: isProduction,
                },
                environmentID,
            })
            .then(() => {
                setFlagsmithReady(true);
            });
    }, []);

    return {
        flagsmith,
        flagsmithReady,
    };
};
