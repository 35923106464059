import {getScript} from "@nfl/rn-shared/src/utils/LoadExternalScript";

export const wrapInPromise = ({
    func,
    params,
}: {
    func?: (param: any) => void | null | undefined;
    params: {
        readonly [key: string]: string;
    };
}): Promise<any> => {
    if (!func) {
        return Promise.resolve();
    }
    return new Promise<any>((resolve, reject) => {
        const callback = (response: any) => {
            if (response?.errorCode === 0) {
                resolve(response);
            } else {
                reject(response);
            }
        };
        func({
            ...params,
            callback,
        });
    });
};

// https://developers.gigya.com/display/GD/Using+The+Web+SDK
export class GigyaWebSDK {
    static _shared: GigyaWebSDK | null | undefined;

    static get shared(): any {
        if (!this._shared) {
            this._shared = new GigyaWebSDK();
            return this._shared;
        }
        return this._shared;
    }

    initSDK(apiKey: string, apiDomain: string): Promise<any> {
        const url = `https://cdns.${apiDomain}/js/gigya.js?apikey=${apiKey}`;
        return getScript({src: url})
            .then(() => {
                if (window.gigya?.accounts?.addEventHandlers) {
                    window.gigya.accounts.addEventHandlers({
                        onError: (e) => {
                            console.warn("Gigya accounts error", e);
                        },
                    });
                }
            })
            .catch((e) => console.warn("Gigya loading error", e));
    }

    isSessionValid(): Promise<boolean> {
        if (window?.gigya?.hasSession) {
            return Promise.resolve(window.gigya.hasSession()).then(
                (r) => r?._result
            );
        }
        return Promise.resolve(false);
    }

    clearSession(): Promise<any> {
        return this.logout();
    }

    login(loginID: string, password: string, params: any): Promise<any> {
        return wrapInPromise({
            func: window?.gigya?.accounts?.login,
            params: {
                ...params,
                loginID,
                password,
            },
        });
    }

    logout(): Promise<any> {
        return wrapInPromise({
            func: window?.gigya?.accounts?.logout,
            params: {},
        });
    }

    socialLogin(provider: string, params: any): Promise<any> {
        return wrapInPromise({
            func: window?.gigya?.accounts?.socialLogin,
            params: {
                ...params,
                provider,
            },
        });
    }

    getConflictingAccount(regToken: string): Promise<any> {
        return wrapInPromise({
            func: window?.gigya?.accounts?.getConflictingAccount,
            params: {
                regToken,
            },
        });
    }

    async register(email: string, password: string, params: any): Promise<any> {
        const {regToken} = await wrapInPromise({
            func: window?.gigya?.accounts?.initRegistration,
            params: {},
        });
        return wrapInPromise({
            func: window?.gigya?.accounts?.register,
            params: {
                ...params,
                password,
                email,
                regToken,
            },
        });
    }

    setAccount(params: any): Promise<any> {
        return wrapInPromise({
            func: window?.gigya?.accounts?.setAccountInfo,
            params,
        });
    }

    getAccount(): Promise<any> {
        return wrapInPromise({
            func: window?.gigya?.accounts?.getAccountInfo,
            params: {format: "jsonp"},
        });
    }

    forgotPassword(email: string): Promise<any> {
        return wrapInPromise({
            func: window?.gigya?.accounts?.resetPassword,
            params: {loginID: email},
        });
    }

    finalizeRegistration(regToken: string): Promise<any> {
        return wrapInPromise({
            func: window?.gigya?.accounts?.finalizeRegistration,
            params: {
                regToken,
            },
        });
    }

    store(type: string, oid: string, uid: string, data: any): Promise<any> {
        return wrapInPromise({
            func: window?.gigya?.ds?.store,
            params: {
                type,
                oid,
                UID: uid,
                data,
            },
        });
    }

    search(query: string, querySig: string, expTime: string): Promise<any> {
        return wrapInPromise({
            func: window?.gigya?.ds?.search,
            params: {
                query,
                querySig,
                expTime,
            },
        });
    }
}
