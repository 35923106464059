export enum TRACK_ACTION {
    ACCOUNT_RECOVERY = "account_recovery",
    CANCELLATION = "cancellation",
    CART_ADD = "cart_add",
    CLICK_ACTION = "click_action",
    DELETE_ACCOUNT = "delete_account",
    DOWNGRADE = "downgrade",
    FORM_SUBMIT = "form_submit",
    MVPD_SIGN_IN = "mvpd_sign_in",
    MVPD_SIGN_OUT = "mvpd_sign_out",
    NEED_HELP = "need_help",
    PURCHASE = "purchase",
    RESUME_BILLING = "resume_billing",
    SIGN_IN = "sign_in",
    SIGN_OUT = "sign_out",
    SIGN_UP = "sign_up",
    UPDATE_ACCOUNT = "update_account",
    UPGRADE = "upgrade",
    VIRTUAL_PV = "virtual_pv",
}

export enum LINK_MODULE {
    ACCOUNT_SECTION_ACTIONS = "Account Section Actions",
    COMPLETE_PURCHASE = "Account Complete Purchase Module",
    CONTINUE_TO_NFL_ID_CTA = "Continue To NFL ID CTA",
    GAME_GRID_CTA = "Game Grid CTA",
    HOME_SECTION_ACTIONS = "Home Section Actions",
    LIVE_AUDIO_CARD = "Live Audio Card",
    LIVE_AUDIO_CARD_MENU = "Live Audio Card Menu",
    LIVE_GAME_CARD = "Live Game Card",
    LIVE_GAME_CARD_MENU = "Live Game Card Menu",
    MOBILE_PURCHASE = "In App Purchase",
    MORE_ABOUT_NFL = "Product Selection Module Promo Banner",
    NFL_NETWORK_VPO = "NFL Plus NFL Network VPO",
    NFL_NETWORK_WATCH_LIVE_CTA = "NFL Network Watch Live CTA",
    PREVIEW_CARD = "Preview Card",
    PREVIEW_CARD_MENU = "Preview Card Menu",
    PURCHASE_CONFIRMATION_CTA = "NFL Plus Purchase Confirmation CTA",
    REPLAY_CARD = "Replay Card",
    REPLAY_CARD_MENU = "Replay Card Menu",
    SUB_PURCHASE_ACTIONS = "NFL Subscription Purchase Actions",
    UPCOMING_CARD = "Upcoming Card",
    UPCOMING_CARD_MENU = "Upcoming Card Menu",
    WATCH_REDZONE = "RedZone Watch Live CTA",
    WEB_PURCHASE = "Web Purchase",
}

export enum LINK_TYPE {
    ERROR = "Error",
    MANAGE_NFL_PLUS_SUB = "Manage NFL Plus Subscription",
    MANAGE_PROFILE = "Manage NFL.com Profile",
    NFL_PLUS_PURCHASE_ACCESS_POINT = "NFL Plus Purchase Access Point",
    PURCHASE = "Purchase",
    SUCCESS = "Success",
}

export enum LINK_NAME {
    CANCEL_SUB = "Cancel Subscription",
    CHANGE_PLAN = "Change Plan",
    CLOSE = "close",
    CONFIRM_PASSWORD = "Confirm Password",
    GET_REDZONE = "Get RedZone With NFL+ Premium",
    LEARN_MORE_REDZONE = "Learn More About RedZone",
    NO_THANKS = "No, Thanks",
    NOW_PLAYING = "Now Playing",
    PREVIEW = "Preview",
    REDEEM_COUPON = "Redeem Coupon",
    REDZONE = "RedZone",
    REPLAY = "Replay",
    RESUME = "Resume",
    SIGN_IN = "Sign In",
    SUBSCRIBE = "Subscribe to NFL+",
    UNSUBSCRIBE = "Unsubscribe",
}

export enum ERROR_MESSAGE {
    EMAIL_UNAVAILABLE = "Email unavailable",
    INVALID_PASSWORD = "Invalid Password",
}

export const GOOGLE_PAY = "Google Pay";
export const APPLE_PAY = "Apple Pay";

export const NFL_PLUS_LINK_MODULE = "NFL Plus Section Actions";
export const NFL_PLUS_CONTENT_CAROUSEL_TRAY = "Content Carousel Tray";
export const NFL_PLUS_MENU = "menu";
export const NFL_PLUS_WATCH_MENU = "NFL Plus Watch Menu";

export const NFL_PLUS_SERIES_CARD = "NFL Plus Show Series Card";
export const SCORES_LINK_MODULE = "Matchup Card";
export const NFL_PLUS_DGX_CARD = "NFL Plus DGX Card";
export const NFL_PLUS_DGX_REPLAY_PLAYLIST = "NFL Plus DGX Replay Playlist";
export const NFL_PLUS_PROMO_BANNER = "Promo Banner";
export const NFL_PLUS_LIVE_GAME_CARD = "NFL Plus Live Game Card";
export const NFL_PLUS_LIVE_AUDIO_CARD = "NFL Plus Live Audio Card";
export const NFL_PLUS_REPLAY_CARD = "NFL Plus Replay Card";
export const NFL_PLUS_REPLAY_SUBSCRIBE_CTA = "NFL Plus Replay Subscribe CTA";
export const NFL_PLUS_SUBSCRIBE_CTA = "Subscribe CTA";
export const NFL_PLUS_LIVE_GAME_WATCH_SUBSCRIBE_CTA =
    "NFL Plus Live Game Watch Live CTA";
export const NFL_PLUS_LIVE_AUDIO_SUBSCRIBE_CTA =
    "NFL Plus Live Audio Subscribe CTA";

export const NFL_NETWORK = "NFL Network";
export const SPECIAL_EVENT = "Special Event";

export enum SITE_NAME {
    NFLCOM = "nfl.com",
    NFLID = "id.nfl.com",
}

// section / subsection
export const ACCOUNT = "account";
export const UPSELL = "upsell";
export const CHECKOUT = "checkout";
export const PURCHASE_CONFIRMATION = "purchase confirmation";
export const CONTINUE_TO_NFL_ID = "continue to nfl id";

// Purchase
export const PURCHASE_LINK_MODULE = "Web Purchase";
export const PURCHASE_LINK_MODULE_MOBILE = "In App Purchase";
export const PURCHASE_LINK_MODULE_PRODUCT_SELECTION =
    "Product Selection Module";

// upsellTypes for plan change
export const PLAN_CHANGE_DOWNGRADE_UPSELLTYPE =
    "subscription-management-module-downgrade";
export const PLAN_CHANGE_UPGRADE_UPSELLTYPE =
    "subscription-management-module-upgrade";

// misc
export const PRODUCT_SELECTION = "product selection";
export const MORE_ABOUT_NFL_LINK_MODULE =
    "Product Selection Module Promo Banner";

export const NFLPlusAccessPoints = {
    DEFAULT: "NFL Plus Access Point",
    LIVE_AUDIO: "Live Audio Access Point",
    NETWORK_CTA: "NFL Network Watch Live CTA",
    PREMIUM_PURCHASE: "NFL Plus Premium Purchase Access Point",
    PURCHASE: "NFL Plus Purchase Access Point",
    VOD: "VOD Access Point",
    WATCH_LIVE: "Watch Live Access Point",
} as const;

export const PRODUCT_CATEGORIES = {
    NFL_PLUS: "NFL PLUS",
    NFL_PLUS_PREMIUM: "NFL PLUS PREMIUM",
};

export const UPSELL_LOCATION = {
    NFL_PLUS: {
        LEAN_MORE: "nfl.com:nfl-plus:learn-more:landing",
    },
    MORE_ABOUT_NFL: "nfl.com:account:upsell:nflplus product selection:season",
};

export const UPSELL_TYPE = {
    MORE_ABOUT_NFL: "product-selection-module-promo-banner",
    PRODUCT_SELECTION_MODULE: "product-selection-module",
    SCORE_STRIP_CTA: "score-strip-cta",
};
