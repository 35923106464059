import {canUseDOM} from "exenv";
import Storage from "react-native-storage";

export const mmkvStorageWrapper = {
    getItem: (key: string): Promise<string> => {
        return Promise.resolve(key);
    },
    setItem: (): Promise<void> => {
        return Promise.resolve();
    },
    removeItem: (): Promise<void> => {
        return Promise.resolve();
    },
};
export const storageModuleBackend = () => mmkvStorageWrapper;

export const storage = new Storage({
    // maximum capacity, default 1000
    size: 1000,

    // Use mmkv for RN, or window.localStorage for web.
    // If not set, data would be lost after reload.
    storageBackend: canUseDOM ? window.localStorage : null,

    // expire time, default 1 day(1000 * 3600 * 24 milliseconds).
    // can be null, which means never expire.
    defaultExpires: 1000 * 3600 * 24,

    // cache data in the memory. default is true.
    enableCache: true,

    // if data was not found in storage or expired,
    // the corresponding sync method will be invoked and return
    // the latest data.
    sync: {},
});
