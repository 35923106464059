export const ROUTES = {
    landing: {
        path: "/",
        pageName: "landing",
        trackingSection: "home",
        subnavs: [],
        component: "Landing",
        mobileFloodlightPixelUrl: [
            "https://9232268.fls.doubleclick.net/activityi;src=9232268;type=lpg_n0;cat=lpg_n00;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;",
        ],
        floodlightPixelUrl: [
            "https://9232268.fls.doubleclick.net/activityi;src=9232268;type=lpg_n0;cat=lpg_n0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;",
            "https://ad.doubleclick.net/ddm/activity/src=1268402;type=homep0;cat=2019_001;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;",
        ],
    },
    home: {
        path: "/howtoplay",
        search: "?leagueId=:leagueId",
        pageName: "home",
        trackingSection: "landing",
        subnavs: [
            {name: "fantasy", selected: "home"},
            {name: "home", selected: "home"},
        ],
        component: "Home",
    },
    fantasyplus: {
        path: "/fantasyplus",
        pageName: "fantasyplus",
        subnavs: [],
        trackingSection: "fantasyplus",
    },
    "trade-machine": {
        path: "/fantasyplus/trade-machine",
        pageName: "trade-machine",
        subnavs: [],
        trackingSection: "trade-machine",
    },
    "trade-analyzer": {
        path: "/fantasyplus/trade-analyzer",
        pageName: "trade-analyzer",
        subnavs: [],
        trackingSection: "trade-analyzer",
    },
    "optimize-pro": {
        path: "/fantasyplus/optimize-pro",
        pageName: "optimize-pro",
        subnavs: [],
        trackingSection: "optimize-pro",
    },
    "waiver-pro": {
        path: "/fantasyplus/waiver-pro",
        pageName: "waiver-pro",
        subnavs: [],
        trackingSection: "waiver-pro",
    },
    "add-player": {
        path: `${process.env.CORE_WEB_BASE_URL}/league/:leagueId/team/:teamId/addplayerselecttodrop`,
        params: {addPlayerId: "playerId"},
        pageName: "add-player",
        external: true,
    },
    "add-player-waiver-bid": {
        path: `${process.env.CORE_WEB_BASE_URL}/league/:leagueId/team/:teamId/addplayerselecttodrop`,
        params: {addPlayerId: "playerId"},
        pageName: "add-player",
        external: true,
    },
    "propose-trade": {
        path: `${process.env.CORE_WEB_BASE_URL}/league/:leagueId/team/:teamId/tradeproposeselecttradeeplayers`,
        params: {
            "tradeePlayerId[]": "tradeePlayerIds",
            tradeeTeamId: "tradeeTeamId",
        },
        pageName: "propose-trade",
        external: true,
    },
    "propose-trade-confirm": {
        path: `${process.env.CORE_WEB_BASE_URL}/league/:leagueId/team/:teamId/tradeproposereview`,
        params: {
            "tradeePlayerId[]": "tradeePlayerIds",
            tradeeTeamId: "tradeeTeamId",
            "traderPlayerId[]": "traderPlayerIds",
        },
        pageName: "propose-trade-confirm",
        external: true,
    },
    "compare-players": {
        path: "/compare-players",
        pageName: "compare-players",
        subnavs: [
            {name: "fantasy", selected: "playersHome"},
            {name: "players"},
        ],
        trackingSection: "compare-players",
    },
    "player-card-carousel": {
        path: `${process.env.CORE_WEB_BASE_URL}/player`,
        pageName: "player-card-carousel",
        subnavs: [],
        trackingSection: "player-card-carousel",
        modal: true,
    },
    leagueHome: {
        path: `${process.env.CORE_WEB_BASE_URL}/league/:leagueId`,
        pageName: "leagueHome",
        subnavs: [
            {name: "fantasy", selected: "leagueHome"},
            {name: "league", selected: "leagueHome"},
        ],
        component: "League",
    },
    teamHome: {
        path: `${process.env.CORE_WEB_BASE_URL}/league/:leagueId/team/:teamId`,
        pageName: "teamHome",
        subnavs: [
            {name: "fantasy", selected: "teamHome"},
            {name: "team", selected: "teamHome"},
        ],
        component: "Team",
    },
    teamMatchup: {
        path: `${process.env.CORE_WEB_BASE_URL}/league/:leagueId/team/:teamId/gamecenter`,
        pageName: "teamMatchup",
        subnavs: [
            {name: "fantasy", selected: "teamMatchup"},
            {name: "gamecenter", selected: "teamMatchup"},
        ],
        component: "Team",
    },
    teamMatchupWeek: {
        path: `${process.env.CORE_WEB_BASE_URL}/league/:leagueId/team/:teamId/gamecenter`,
        search: "?week=:weekId",
        pageName: "teamMatchup",
        subnavs: [
            {name: "fantasy", selected: "teamMatchup"},
            {name: "gamecenter", selected: "teamMatchup"},
        ],
        component: "Team",
    },
    teamDropPlayer: {
        path: `${process.env.CORE_WEB_BASE_URL}/league/:leagueId/team/:teamId/dropplayer`,
        pageName: "teamDropPlayer",
        subnavs: [
            {name: "fantasy", selected: "teamHome"},
            {name: "team", selected: "teamDropPlayer"},
        ],
        component: "Team",
    },
    teamWaiverReport: {
        path: `${process.env.CORE_WEB_BASE_URL}/league/:leagueId/team/:teamId/waiverreport`,
        pageName: "teamWaiverReport",
        subnavs: [
            {name: "fantasy", selected: "teamHome"},
            {name: "team", selected: "teamWaiverReport"},
        ],
        component: "Team",
    },
    commishHome: {
        path: `${process.env.CORE_WEB_BASE_URL}/league/:leagueId/manage`,
        pageName: "commishHome",
        subnavs: [{name: "fantasy", selected: "commishHome"}],
        component: "League",
    },
    commishTools: {
        path: `${process.env.CORE_WEB_BASE_URL}/league/:leagueId/manage/:tool`,
        pageName: "commishTools",
        subnavs: [{name: "fantasy", selected: "commishHome"}],
        component: "League",
    },
    teamWatchList: {
        path: `${process.env.CORE_WEB_BASE_URL}/league/:leagueId/team/:teamId/watchlist`,
        pageName: "teamWatchList",
        subnavs: [
            {name: "fantasy", selected: "teamHome"},
            {name: "team", selected: "teamWatchList"},
        ],
        component: "Team",
    },
    teamTradingBlock: {
        path: `${process.env.CORE_WEB_BASE_URL}/league/:leagueId/team/:teamId/tradingblock`,
        pageName: "teamTradingBlock",
        subnavs: [
            {name: "fantasy", selected: "teamHome"},
            {name: "team", selected: "teamTradingBlock"},
        ],
        component: "Team",
    },
    teamKeepersEdit: {
        path: `${process.env.CORE_WEB_BASE_URL}/league/:leagueId/team/:teamId/keepersedit`,
        pageName: "teamKeepersEdit",
        subnavs: [
            {name: "fantasy", selected: "teamHome"},
            {name: "team", selected: "teamKeepersEdit"},
        ],
        component: "Team",
    },
    teamSettingsEdit: {
        path: `${process.env.CORE_WEB_BASE_URL}/league/:leagueId/team/:teamId/editsettings`,
        pageName: "teamSettingsEdit",
        subnavs: [
            {name: "fantasy", selected: "teamHome"},
            {name: "team", selected: "teamSettingsEdit"},
        ],
        component: "Team",
    },
    teamAlerts: {
        path: `${process.env.CORE_WEB_BASE_URL}/league/:leagueId/team/:teamId/alerts`,
        pageName: "teamAlerts",
        subnavs: [
            {name: "fantasy", selected: "teamHome"},
            {name: "team", selected: "teamAlerts"},
        ],
        component: "Team",
    },
    teamPreRank: {
        path: `${process.env.CORE_WEB_BASE_URL}/league/:leagueId/team/:teamId/prerank`,
        pageName: "teamPreRank",
        subnavs: [
            {name: "fantasy", selected: "teamHome"},
            {name: "team", selected: "teamPreRank"},
        ],
        component: "Team",
    },
    researchHome: {
        path: `${process.env.CORE_WEB_BASE_URL}/research`,
        pageName: "researchHome",
        subnavs: [{name: "research", selected: "researchHome"}],
        component: "Page",
    },
    help: {
        path: `${process.env.CORE_WEB_BASE_URL}/help`,
        search: "?leagueId=:leagueId",
        pageName: "help",
        subnavs: [
            {name: "fantasy", selected: "help"},
            {name: "home", selected: "home"},
        ],
        component: "Page",
    },
    draftCenter: {
        path: `${process.env.CORE_WEB_BASE_URL}/draftcenter`,
        pageName: "draftCenter",
        subnavs: [{name: "draftcenter", selected: "draftCenterHome"}],
        component: "Page",
    },
    playersHome: {
        path: `${process.env.CORE_WEB_BASE_URL}/league/:leagueId/players`,
        pageName: "playersHome",
        subnavs: [
            {name: "fantasy", selected: "playersHome"},
            {name: "players", selected: "playersHome"},
        ],
        component: "Page",
    },
    playersSearch: {
        path: `${process.env.CORE_WEB_BASE_URL}/league/:leagueId/players/search`,
        pageName: "playersSearch",
        subnavs: [
            {name: "fantasy", selected: "playersHome"},
            {name: "players", selected: "playersHome"},
        ],
        component: "Page",
    },
    playersHomeTrade: {
        path: `${process.env.CORE_WEB_BASE_URL}/league/:leagueId/players`,
        pageName: "playersHome",
        search: "?playerStatus=owned",
        subnavs: [
            {name: "fantasy", selected: "playersHome"},
            {name: "players", selected: "playersHome"},
        ],
        component: "Page",
    },
    myLeagues: {
        path: `${process.env.CORE_WEB_BASE_URL}/myleagues`,
        search: "?leagueId=:leagueId",
        pageName: "myLeagues",
        subnavs: [
            {name: "fantasy", selected: "home"},
            {name: "home", selected: "myLeagues"},
        ],
        component: "Page",
    },
    leaderboard: {
        path: `${process.env.CORE_WEB_BASE_URL}/leaderboard`,
        pageName: "leaderboard",
        subnavs: [
            {name: "fantasy", selected: "home"},
            {name: "home", selected: "leaderboard"},
        ],
        component: "Page",
    },
    leagueEmail: {
        path: `${process.env.CORE_WEB_BASE_URL}/league/:leagueId/email`,
        pageName: "leagueEmail",
        subnavs: [
            {name: "fantasy", selected: "leagueHome"},
            {name: "league", selected: "leagueEmail"},
        ],
        component: "League",
    },
    leagueManagers: {
        path: `${process.env.CORE_WEB_BASE_URL}/league/:leagueId/owners`,
        pageName: "leagueManagers",
        subnavs: [
            {name: "fantasy", selected: "leagueHome"},
            {name: "league", selected: "leagueManagers"},
        ],
        component: "League",
    },
    leagueDepthCharts: {
        path: `${process.env.CORE_WEB_BASE_URL}/league/:leagueId/depthcharts`,
        pageName: "leagueDepthCharts",
        subnavs: [
            {name: "fantasy", selected: "leagueHome"},
            {name: "league", selected: "leagueDepthCharts"},
        ],
        component: "League",
    },
    leagueMessages: {
        path: `${process.env.CORE_WEB_BASE_URL}/league/:leagueId/discussions`,
        pageName: "leagueMessages",
        subnavs: [
            {name: "fantasy", selected: "leagueHome"},
            {name: "league", selected: "leagueMessages"},
        ],
        component: "League",
    },
    leagueTransactions: {
        path: `${process.env.CORE_WEB_BASE_URL}/league/:leagueId/transactions`,
        pageName: "leagueTransactions",
        subnavs: [
            {name: "fantasy", selected: "leagueHome"},
            {name: "league", selected: "leagueTransactions"},
        ],
        component: "League",
    },
    leagueTransactionsTeam: {
        path: `${process.env.CORE_WEB_BASE_URL}/league/:leagueId/transactions`,
        search: "?teamId=:teamId&transactionTeamId=:teamId",
        pageName: "leagueTransactions",
        subnavs: [
            {name: "fantasy", selected: "leagueHome"},
            {name: "league", selected: "leagueTransactions"},
        ],
        component: "League",
    },
    leagueFees: {
        path: `${process.env.CORE_WEB_BASE_URL}/league/:leagueId/fees`,
        pageName: "leagueFees",
        subnavs: [
            {name: "fantasy", selected: "leagueHome"},
            {name: "league", selected: "leagueFees"},
        ],
        component: "League",
    },
    leagueDraftResults: {
        path: `${process.env.CORE_WEB_BASE_URL}/league/:leagueId/draftresults`,
        pageName: "leagueDraftResults",
        subnavs: [
            {name: "fantasy", selected: "leagueHome"},
            {name: "league", selected: "leagueDraftResults"},
        ],
        component: "League",
    },
    leagueDraftInfo: {
        path: `${process.env.CORE_WEB_BASE_URL}/league/:leagueId/draftinfo`,
        pageName: "leagueDraftInfo",
        subnavs: [
            {name: "fantasy", selected: "leagueHome"},
            {name: "league", selected: "leagueDraftInfo"},
        ],
        component: "League",
    },
    leagueSettings: {
        path: `${process.env.CORE_WEB_BASE_URL}/league/:leagueId/settings`,
        pageName: "leagueSettings",
        subnavs: [
            {name: "fantasy", selected: "leagueHome"},
            {name: "league", selected: "leagueSettings"},
        ],
        component: "League",
    },
    leagueHistory: {
        path: `${process.env.CORE_WEB_BASE_URL}/league/:leagueId/history`,
        pageName: "leagueHistory",
        subnavs: [
            {name: "fantasy", selected: "leagueHome"},
            {name: "league", selected: "leagueHistory"},
        ],
        component: "League",
    },
    leagueHistoryCustom: {
        path: `${process.env.CORE_WEB_BASE_URL}/league/:leagueId/history/(.*)`,
        pageName: "leagueHistory",
        subnavs: [
            {name: "fantasy", selected: "leagueHome"},
            {name: "league", selected: "leagueHistory"},
        ],
        component: "League",
    },
    leaguePolls: {
        path: `${process.env.CORE_WEB_BASE_URL}/league/:leagueId/poll`,
        pageName: "leaguePolls",
        subnavs: [
            {name: "fantasy", selected: "leagueHome"},
            {name: "league", selected: "leaguePolls"},
        ],
        component: "League",
    },
    myScoreboard: {
        path: `${process.env.CORE_WEB_BASE_URL}/myscoreboard`,
        pageName: "myScoreboard",
        search: "?leagueId=:leagueId",
        subnavs: [
            {name: "fantasy", selected: "leagueHome"},
            {name: "home", selected: "myScoreboard"},
        ],
        component: "Page",
    },
    playersLeaders: {
        path: `${process.env.CORE_WEB_BASE_URL}/league/:leagueId/players/leaders`,
        pageName: "playersLeaders",
        subnavs: [
            {name: "fantasy", selected: "playersHome"},
            {name: "players", selected: "playersLeaders"},
        ],
        component: "Page",
    },
    playersNews: {
        path: `${process.env.CORE_WEB_BASE_URL}/league/:leagueId/players/news`,
        pageName: "playersNews",
        subnavs: [
            {name: "fantasy", selected: "playersHome"},
            {name: "players", selected: "playersNews"},
        ],
        component: "Page",
    },
    playersInjuries: {
        path: `${process.env.CORE_WEB_BASE_URL}/league/:leagueId/players/injuries`,
        pageName: "playersInjuries",
        subnavs: [
            {name: "fantasy", selected: "playersHome"},
            {name: "players", selected: "playersInjuries"},
        ],
        component: "Page",
    },
    playersStatCorrections: {
        path: `${process.env.CORE_WEB_BASE_URL}/league/:leagueId/players/statcorrections`,
        pageName: "playersStatCorrections",
        subnavs: [
            {name: "fantasy", selected: "playersHome"},
            {name: "players", selected: "playersStatCorrections"},
        ],
        component: "Page",
    },
    researchRankings: {
        path: `${process.env.CORE_WEB_BASE_URL}/research/rankings`,
        pageName: "researchRankings",
        subnavs: [{name: "research", selected: "researchRankings"}],
        component: "Page",
    },
    researchPointsAgainst: {
        path: `${process.env.CORE_WEB_BASE_URL}/research/pointsagainst`,
        pageName: "pointsAgainst",
        subnavs: [{name: "research", selected: "researchPointsAgainst"}],
        component: "Page",
    },
    researchProjections: {
        path: `${process.env.CORE_WEB_BASE_URL}/research/projections`,
        pageName: "researchProjections",
        subnavs: [{name: "research", selected: "researchProjections"}],
        component: "Page",
    },
    researchScoringLeaders: {
        path: `${process.env.CORE_WEB_BASE_URL}/research/scoringleaders`,
        pageName: "researchScoringLeaders",
        subnavs: [{name: "research", selected: "researchScoringLeaders"}],
        component: "Page",
    },
    researchElitePerformers: {
        path: `${process.env.CORE_WEB_BASE_URL}/eliteperformers`,
        pageName: "researchElitePerformers",
        subnavs: [{name: "research", selected: "researchElitePerformers"}],
        component: "Page",
    },
    researchTrends: {
        path: `${process.env.CORE_WEB_BASE_URL}/research/trends`,
        pageName: "researchTrends",
        subnavs: [{name: "research", selected: "researchTrends"}],
        component: "Page",
    },
    researchStatsCorrections: {
        path: `${process.env.CORE_WEB_BASE_URL}/research/statcorrections`,
        pageName: "researchStatsCorrections",
        subnavs: [{name: "research", selected: "researchStatsCorrections"}],
        component: "Page",
    },
    researchPlayers: {
        path: `${process.env.CORE_WEB_BASE_URL}/research/players`,
        pageName: "researchPlayers",
        subnavs: [{name: "research", selected: "researchPlayers"}],
        component: "Page",
    },
    researchPlayersSearch: {
        path: `${process.env.CORE_WEB_BASE_URL}/research/search`,
        pageName: "researchPlayersSearch",
        subnavs: [{name: "research", selected: "researchPlayers"}],
        component: "Page",
    },
    registrationLeagueDirectory: {
        path: `${process.env.CORE_WEB_BASE_URL}/registration/leagueDirectory`,
        pageName: "registrationLeagueDirectory",
        search: "?experience=1&leagueType=public",
        subnavs: [
            {name: "fantasy", selected: "home"},
            {name: "home", selected: "registrationLeagueDirectory"},
        ],
    },
    draftCenterHome: {
        path: `${process.env.CORE_WEB_BASE_URL}/draftcenter`,
        pageName: "draftCenterHome",
        subnavs: [{name: "draftcenter", selected: "draftCenterHome"}],
        component: "Page",
    },
    draftCenterBreakdown: {
        path: `${process.env.CORE_WEB_BASE_URL}/draftcenter/breakdown`,
        pageName: "draftCenterBreakdown",
        subnavs: [{name: "draftcenter", selected: "draftCenterBreakdown"}],
        component: "Page",
    },
    draftCenterMockDrafts: {
        path: `${process.env.CORE_WEB_BASE_URL}/draftcenter/mockdrafts`,
        pageName: "draftCenterMockDrafts",
        subnavs: [{name: "draftcenter", selected: "draftCenterMockDrafts"}],
        component: "Page",
    },
    draftCenterLiveDraftLobby: {
        path: `${process.env.CORE_WEB_BASE_URL}/draftcenter/livedraftlobby`,
        pageName: "draftCenterLiveDraftLobby",
        subnavs: [{name: "draftcenter", selected: "draftCenterLiveDraftLobby"}],
        component: "Page",
    },
    draftCenterHelp: {
        path: `${process.env.CORE_WEB_BASE_URL}/draftcenter/help`,
        pageName: "draftCenterHelp",
        subnavs: [{name: "draftcenter", selected: "draftCenterHelp"}],
        component: "Page",
    },
};
