import * as React from "react";
import {Col, Collapse, Row} from "reactstrap";
import * as uniqueSlug from "unique-slug";

const SEC_OPEN = "/static/images/svg/ic_up.svg";
const SEC_CLOSED = "/static/images/svg/ic_down.svg";

type CollapseSectionProps = {
    expanded?: boolean;
    title: string;
    children: React.ReactNode;
};

type CollapseSectionState = {
    isOpen: boolean;
};

class CollapseSection extends React.Component<
    CollapseSectionProps,
    CollapseSectionState
> {
    constructor(props) {
        super(props);
        const {expanded = false} = props;
        this.state = {
            isOpen: !!expanded,
        };
    }

    toggleView = () => {
        this.setState((prevState) => ({
            isOpen: !prevState.isOpen,
        }));
    };

    render() {
        const {isOpen} = this.state;
        const classNames = isOpen
            ? ["collapse-container", "collapse-open"]
            : ["collapse-container"];
        const glyph = isOpen ? SEC_OPEN : SEC_CLOSED;
        const sectionId = uniqueSlug(this.props.title);
        return (
            <Row noGutters>
                <Col className={classNames.join(" ")} xs={12}>
                    <button
                        aria-controls={`${sectionId}-data`}
                        aria-expanded={this.state.isOpen ? "true" : "false"}
                        className="collapse-header-button"
                        id={`${sectionId}-label`}
                        type="button"
                        onClick={this.toggleView}
                    >
                        <Row noGutters className="collapse-header">
                            <Col xs={10}>
                                <span className="title">
                                    {this.props.title}
                                </span>
                            </Col>
                            <Col className="toggle" xs={2}>
                                <img alt="toggle_button" src={glyph} />
                            </Col>
                        </Row>
                    </button>
                    <Row noGutters>
                        <Col xs={12}>
                            <Collapse
                                aria-labelledby={`${sectionId}-label`}
                                className="data"
                                id={`${sectionId}-data`}
                                isOpen={this.state.isOpen}
                                role="region"
                            >
                                {this.props.children}
                            </Collapse>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}

export default CollapseSection;
